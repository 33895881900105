// utils/slugify.js

export const slugify = (text) => {
    return text
      .toString()                          // Convert to string
      .toLowerCase()                       // Convert to lowercase
      .trim()                              // Trim whitespace
      .replace(/\s+/g, '-')                // Replace spaces with -
      .replace(/[^\w\-]+/g, '')            // Remove all non-word chars
      .replace(/\-\-+/g, '-');             // Replace multiple - with single -
  };
  