import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import BookingForm from './BookingForm';
import './HeaderLayout.css';
import { PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const HeaderLayout = ({ onBooking }) => {
  return (
    <>
      <Layout className="header-layout">
        <div className="header-content">
          <Row gutter={[32, 32]} align="middle" justify="center">
            <Col xs={24} sm={12} lg={10}>
              <BookingForm onFinish={onBooking} />
            </Col>
            <Col className="header-column" xs={24} sm={12} lg={14}>
              <Title align="middle" level={1} className="header-title">
                Your Journey, Our Commitment
              </Title>
              <Paragraph align="middle" className="header-subtitle">
                Reliable rides at your fingertips.
              </Paragraph>
              <Paragraph align="middle" className="header-description">
                Whether it's a daily commute or a special occasion, we’re here to make your travel seamless, safe, and stress-free.
              </Paragraph>
              <a href="tel:+919632833382" className="call-us-section">
                <PhoneOutlined className="call-us-icon" />
                <span className="call-us-heading">Prefer to book by phone?</span>
                <span className="call-us-number">Call us at +91 96328 33382</span>
              </a>
            </Col>
          </Row>
        </div>
      </Layout>

      <div className="floating-buttons">
        <div className="floating-button phone">
          <a href="tel:+919632833382">
            <PhoneOutlined />
          </a>
        </div>
        <div className="floating-button whatsapp">
          <a href="https://wa.me/919632833382" target="_blank" rel="noopener noreferrer">
            <WhatsAppOutlined />
          </a>
        </div>
      </div>
    </>
  );
};

export default HeaderLayout;
