import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Card, Rate, Typography } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import './CustomerReviews.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import harishImage from '../assets/citytaxis_customer3.png';
import shriyamImage from '../assets/citytaxis-customer-review.png';
import KikoImage from '../assets/citytaxis_customer.png';

const { Title } = Typography;

const reviews = [
  {
    name: 'Harish Tiwari',
    rating: 5,
    comment: '“I had to catch an early morning flight, and my ride bailed on me at the last minute. Panicking, I tried CityTaxis The booking process was a breeze, and the driver arrived promptly, ensuring I reached the airport on time. A lifesaver at 3 AM!"​    ',
    photo: harishImage,
    
  },
  {
    name: 'Shriyam Prasad',
    rating: 4,
    comment: 'I travel frequently between Ranchi to Dhanbad by taxi, and CityTaxis has been a godsend! Their reliable airport services, with timely pickups and drops, ensure I never have to worry about missing a flight or postponing my plans.',
    photo: shriyamImage,
  },
  {
    name: 'Nikhil Kiko',
    rating: 5,
    comment: 'I decided on a quick weekend getaway to Hazaribagh National Park, and CityTaxis made it possible! The online quotation and booking process were seamless, and the journey was just what I hoped for - peaceful and scenic.',
    photo: KikoImage,
  },
];

const CustomerReviews = () => {
  const sliderRef = useRef(null);
  const [isTouching, setIsTouching] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    // speed: 5000,
    slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    // sliderRef.current.slickPause();
  };

  const handleMouseLeave = () => {
    if (!isTouching) {
      sliderRef.current.slickPlay();
    }
  };

  const handleTouchStart = () => {
    sliderRef.current.slickPause();
    setIsTouching(true);
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
    sliderRef.current.slickPlay();
  };

  return (
    <div
      className="customer-reviews"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <Title level={3} className="customer-reviews-title">
        What our customers are saying:
      </Title>
      <Slider ref={sliderRef} {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-slide">
            <Card className="review-card">
              <img src={review.photo} alt={review.name} className="review-photo" />
              <Rate disabled defaultValue={review.rating} />
              <p className="review-comment">{review.comment}</p>
              <p className="review-name">
                <SmileOutlined /> {review.name}
              </p>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomerReviews;
