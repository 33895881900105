import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import {
  CarOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  GiftOutlined,
  ShoppingOutlined,
  HeartOutlined,
} from '@ant-design/icons'; // Importing Ant Design icons
import './ServicesSection.css';

const { Title, Paragraph } = Typography;

const services = [
  {
    title: 'Airport Transfers',
    description: 'We provide timely and comfortable airport transfers with professional drivers and well-maintained vehicles.',
    icon: <CarOutlined />, // Icon for Airport Transfers
  },
  {
    title: 'City Tours',
    description: 'Explore the city with our comprehensive city tour services, available for both individuals and groups.',
    icon: <EnvironmentOutlined />, // Icon for City Tours
  },
  {
    title: 'Corporate Services',
    description: 'Our corporate services include reliable transportation for business meetings, events, and more.',
    icon: <TeamOutlined />, // Icon for Corporate Services
  },
  {
    title: 'Special Events',
    description: 'Book our services for weddings, parties, and other special events. We ensure a smooth and luxurious experience.',
    icon: <GiftOutlined />, // Icon for Special Events
  },
  {
    title: 'Shopping Trips',
    description: 'Enjoy a hassle-free shopping experience with our dedicated shopping trip services, perfect for visiting malls and markets.',
    icon: <ShoppingOutlined />, // Icon for Shopping Trips
  },
  {
    title: 'Healthcare Visits',
    description: 'We provide transportation for healthcare visits, ensuring you reach your appointments on time with comfort and care.',
    icon: <HeartOutlined />, // Icon for Healthcare Visits
  },
];

const ServicesSection = () => {
  return (
    <div className="services-section">
      <Title level={3} className="services-title">Our Services</Title>
      <Row gutter={[16, 16]} justify="center">
        {services.map((service, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card hoverable className="service-card">
              <div className="icon-wrapper">
                {service.icon}
              </div>
              <Title level={4} className="service-title">{service.title}</Title>
              <Paragraph>{service.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServicesSection;
