import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPostItem.css';

const BlogPostItem = ({ post }) => {
  return (
    <Link to={`/blog/${post.slug}`} className="blog-post-item">
      <img src={post.image} alt={post.title} className="post-image" />
      <div className="post-content">
        <h2 className="post-title">{post.title}</h2>
        <p className="post-date">{new Date(post.date).toLocaleDateString()}</p> {/* Improved date formatting */}
        <p className="post-summary">{post.summary}</p>
      </div>
    </Link>
  );
};

export default BlogPostItem;
