// About.js
import React from 'react';
import HeaderLayout from '../components/Customer/HeaderLayout';
import AboutContent from '../components/Customer/AboutContent';
import Navbar from '../components/Customer/Navbar';
import ContactContent from '../components/Customer/ContactContent';
import AppFooter from '../components/Common/Footer';

const Contact = () => {
  return (
    <>
      {/* <HeaderLayout /> */}
      <ContactContent />
      {/* <AppFooter/> */}

    </>
  );
};

export default Contact;
