// About.js
import React from 'react';
import FAQSection from '../components/Customer/FAQSection';

const About = () => {

  return (
    <>
      <FAQSection />
    </>
  );
};

export default About;
