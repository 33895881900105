import React, { useState } from 'react';
import { Input, List, Card, Row, Col, Button } from 'antd';
import { SearchOutlined, MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import './Support.css';

const Support = () => {
  // Dummy data for support articles
  const [articles, setArticles] = useState([
    { id: 1, title: 'How to book a ride?', description: 'Learn how to easily book a ride using our app.' },
    { id: 2, title: 'Payment methods', description: 'Explore the various payment options available for booking rides.' },
    { id: 3, title: 'Cancelling a ride', description: 'Steps to cancel a ride and what to expect.' },
    { id: 4, title: 'Safety protocols', description: 'Read about our safety measures and protocols.' },
    { id: 5, title: 'Using ride points', description: 'How to earn and redeem ride points for discounts.' },
    { id: 6, title: 'Account setup and verification', description: 'Step-by-step guide to setting up and verifying your account.' },
    { id: 7, title: 'Lost and Found', description: 'What to do if you leave something behind in a ride.' },
    { id: 8, title: 'Driver ratings and feedback', description: 'How to rate your driver and leave feedback after a ride.' },
    { id: 9, title: 'Promo codes and discounts', description: 'How to apply promo codes and get discounts on your rides.' },
    { id: 10, title: 'Ride history', description: 'View your past rides and get detailed trip information.' },
  ]);

  const [filteredArticles, setFilteredArticles] = useState(articles);

  const onSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = articles.filter(article => 
      article.title.toLowerCase().includes(value)
    );
    setFilteredArticles(filtered);
  };

  return (
    <div className="support-container">
      <h2 className="support-title">Support Center</h2>
      <Input
        placeholder="Search for help articles"
        size="large"
        prefix={<SearchOutlined style={{ color: '#bbbbbb' }} />}
        onChange={onSearch}
        className="support-search"
      />

      <List
        className="support-articles"
        itemLayout="vertical"
        dataSource={filteredArticles}
        renderItem={article => (
          <Card className="support-article-card" key={article.id} hoverable>
            <List.Item>
              <List.Item.Meta
                title={<h3>{article.title}</h3>}
                description={article.description}
              />
            </List.Item>
          </Card>
        )}
      />

      <div className="support-contact">
        <h3>Need more help? Contact us</h3>
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Button type="primary" icon={<MailOutlined />} size="large" className="contact-button">
              Email Us
            </Button>
          </Col>
          <Col xs={24} sm={8}>
            <Button type="primary" icon={<MessageOutlined />} size="large" className="contact-button">
              Chat with Us
            </Button>
          </Col>
          <Col xs={24} sm={8}>
            <Button type="primary" icon={<PhoneOutlined />} size="large" className="contact-button">
              Call Us
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Support;
