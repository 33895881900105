// About.js
import React from 'react';
import AboutContent from '../components/Customer/AboutContent';

const About = () => {

  return (
    <>
      <AboutContent />
    </>
  );
};

export default About;
