import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import { CalendarOutlined, PhoneOutlined, CreditCardOutlined } from '@ant-design/icons';
import './BookingProcessSection.css';

const { Title, Paragraph } = Typography;

const steps = [
  {
    icon: <CalendarOutlined />,
    title: 'Step 1: Choose Your Ride',
    description: 'Select the type of service you need, whether it’s a local ride, airport transfer, or outstation trip. Enter your pick-up and drop-off locations along with the date and time of your journey.'
  },
  {
    icon: <PhoneOutlined />,
    title: 'Step 2: Confirm Your Details',
    description: 'Review the details of your booking, including the vehicle type and the number of passengers. Ensure all information is accurate before proceeding.'
  },
  {
    icon: <CreditCardOutlined />,
    title: 'Step 3: Make Payment',
    description: 'Choose your preferred payment method. We accept all major credit and debit cards, as well as online payment options. Complete the payment to confirm your booking.'
  },
  {
    icon: <PhoneOutlined />,
    title: 'Step 4: Receive Confirmation',
    description: 'Once your booking is confirmed, you will receive a confirmation message with the driver’s details and payment receipt. Your ride is now booked and ready!'
  },
];

const BookingProcessSection = () => {
  return (
    <div className="booking-process-section">
      <Title level={3} className="booking-process-title">How to Book a Taxi</Title>
      <Row gutter={[16, 16]} justify="center">
        {steps.map((step, index) => (
          <Col xs={24} sm={12} md={6} key={index}>
            <Card hoverable className="booking-step-card">
              <div className="icon-wrapper">{step.icon}</div>
              <Title level={4} className="booking-step-title">{step.title}</Title>
              <Paragraph className="booking-step-description">{step.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BookingProcessSection;
