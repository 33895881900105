import React, { useState, useEffect } from 'react';
import { Layout, Typography, Row, Col, Card, Spin, Input, Empty } from 'antd';
import { getAllCitiesMetaData } from '../../services/api'; // Assuming this function fetches the list of cities from your database
import { SearchOutlined } from '@ant-design/icons';
import './CitiesPage.css';

const { Content } = Layout;
const { Title } = Typography;
const { Meta } = Card;

const CitiesPage = () => {
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCitiesData = async () => {
      try {
        const data = await getAllCitiesMetaData(); // Fetch data from your database
        setCities(data);
        setFilteredCities(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load cities');
        setLoading(false);
      }
    };

    fetchCitiesData();
  }, []);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredData = cities.filter(city =>
      city.name.toLowerCase().includes(searchValue) ||
      city.meta_description.toLowerCase().includes(searchValue)
    );
    setFilteredCities(filteredData);
  };

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: 'auto' }} />;
  }

  if (error) {
    return <h2>{error}</h2>;
  }

  return (
    <Layout className="cities-layout">
      <Content style={{ padding: '50px', maxWidth: '1200px', margin: '0 auto' }}>
        <Title level={2} className="cities-title">Cities We Serve</Title>
        
        {/* Search Bar */}
        <Input
          placeholder="Search for a city..."
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
          allowClear
        />

        {/* City Cards */}
        {filteredCities.length > 0 ? (
          <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
            {filteredCities.map((city) => (
              <Col xs={24} sm={12} md={8} lg={6} key={city.name}>
                <Card
                  hoverable
                  // cover={
                  //   <img
                  //     alt={city.name}
                  //     src={city.imageUrl || 'https://via.placeholder.com/300'} // Default image if none provided
                  //     style={{ height: '180px', objectFit: 'cover' }}
                  //   />
                  // }
                  onClick={() => window.location.href = `/cities/${city.name.toLowerCase()}`}
                  className="city-card"
                >
                  <Meta title={city.name} description={city.meta_description} />
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty description="No cities found" style={{ marginTop: '50px' }} />
        )}
      </Content>
    </Layout>
  );
};

export default CitiesPage;
