import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Progress, Badge, Avatar, Card, Row, Col, Button, Divider } from 'antd';
import { UserOutlined, StarOutlined, TrophyOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../../../redux/userSlice';
import { logout } from '../../../redux/authSlice'; // Import the logout action

import './UserProfile.css';

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);
  const status = useSelector(state => state.user.status);

  useEffect(() => {
    const isLocalhost = window.location.hostname === "localhost";
    const token = localStorage.getItem('authToken');
    const storedUser = localStorage.getItem('user');

    if (!isLocalhost) {
      if (!token || !storedUser) {
        message.warning('Please log in to view your profile.');
        navigate('/login');
        return;
      }
    }
    
    if (!user && status === 'idle') {
      const userId = JSON.parse(storedUser)._id;
      dispatch(fetchUserProfile(userId));
    }
  }, [navigate, dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    message.success('Logged out successfully!');
    navigate('/login');
  };

  if (status === 'loading' || !user) {
    return <div>Loading...</div>; // Display a loader while the user data is being fetched
  }

  return (
    <div className="user-profile">
      <Card className="profile-card" bordered={false} style={{ padding: '20px', borderRadius: '10px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6} style={{ textAlign: 'center' }}>
            <Avatar 
              size={150} 
              src={user.profilePicture} 
              icon={<UserOutlined />} 
              className="profile-avatar" 
              style={{ marginBottom: '20px' }}
            />
            <Button 
              icon={<EditOutlined />} 
              className="edit-profile-btn" 
              style={{ width: '100%', marginBottom: '10px' }}
            >
              Edit Profile
            </Button>
            <Button 
              icon={<LogoutOutlined />} 
              className="logout-btn" 
              type="danger" 
              onClick={handleLogout} 
              style={{ width: '100%' }}
            >
              Logout
            </Button>
          </Col>
          <Col xs={24} md={18}>
            <h2 className="profile-name" style={{ marginBottom: '0' }}>{user.name}</h2>
            <p className="profile-email" style={{ color: 'rgba(0, 0, 0, 0.45)', marginBottom: '20px' }}>{user.email}</p>
            <Divider />
            <div className="profile-details" style={{ fontSize: '16px', lineHeight: '1.5' }}>
              <div className="detail-item">
                <UserOutlined /> Member since: {new Date(user.memberSince).toLocaleDateString()}
              </div>
              <div className="detail-item">
                <StarOutlined /> Total Points: {user.points}
              </div>
              <div className="detail-item">
                <TrophyOutlined /> Achievements: {user.badges.length}
              </div>
              <div className="detail-item">
                <Badge 
                  count={user.level} 
                  style={{ backgroundColor: '#52c41a', marginTop: '10px', fontSize: '14px' }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default UserProfile;
