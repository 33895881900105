import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Modal, Input, Button, Drawer } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import './Navbar.css';

const { Header } = Layout;
const { TextArea } = Input;

const Navbar = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setChatInput('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleContactUs = () => {

  }

  const handleAuth = () => {
    navigate('/login')


  }
  
  

  const aboutMenu = (
    <Menu>
      <Menu.Item key="/about-us">
        <Link to="/about-us">About Us</Link>
      </Menu.Item>
      <Menu.Item key="/our-team">
        <Link to="/our-team">Our Team</Link>
      </Menu.Item>
      <Menu.Item key="/careers">
        <Link to="/careers">Careers</Link>
      </Menu.Item>
    </Menu>
  );

  const servicesMenu = (
    <Menu>
      <Menu.Item key="/services/airport-transfer">
        <Link to="/services/airport-transfer">Airport Transfer</Link>
      </Menu.Item>
      <Menu.Item key="/services/city-tours">
        <Link to="/services/city-tours">City Tours</Link>
      </Menu.Item>
      <Menu.Item key="/services/corporate-travel">
        <Link to="/services/corporate-travel">Corporate Travel</Link>
      </Menu.Item>
    </Menu>
  );

  const fleetMenu = (
    <Menu>
      <Menu.Item key="/fleet/economy">
        <Link to="/fleet/economy">Economy</Link>
      </Menu.Item>
      <Menu.Item key="/fleet/luxury">
        <Link to="/fleet/luxury">Luxury</Link>
      </Menu.Item>
      <Menu.Item key="/fleet/suv">
        <Link to="/fleet/suv">SUV</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="navbar">
      <div className="logo">
        <Link to="/">Indore Cabs</Link>
      </div>
      <Menu mode="horizontal" className="menu" selectedKeys={[location.pathname]}>
        <Menu.Item key="/">
          <Link to="/">Home</Link>
        </Menu.Item>
        {/* <Menu.SubMenu key="about" title={<span>About <DownOutlined /></span>}>
          {aboutMenu}
        </Menu.SubMenu>
        <Menu.SubMenu key="services" title={<span>Services <DownOutlined /></span>}>
          {servicesMenu}
        </Menu.SubMenu>
        <Menu.SubMenu key="fleet" title={<span>Fleet <DownOutlined /></span>}>
          {fleetMenu}
        </Menu.SubMenu> */}
        <Menu.Item key="/about">
          <Link to="/about">About us</Link>
        </Menu.Item>


        <Menu.Item key="/pricing">
          <Link to="/pricing">Pricing</Link>
        </Menu.Item>




        <Menu.Item key="/blog">
          <Link to="/blog">Blog</Link>
        </Menu.Item>
        <Menu.Item key="/contact">
          <Link to="/contact">Contact</Link>
        </Menu.Item>

        <Menu.Item key="ask-question" className='loginbutton'>
            <Button type="primary" onClick={handleAuth} block>
              Login/Signup
            </Button>
          </Menu.Item>
      </Menu>
      <MenuOutlined className="menu-icon" onClick={showDrawer} />
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={onClose}
        open={visible}
        width="75%"
      >
        <Menu mode="vertical" className="drawer-menu" selectedKeys={[location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Home</Link>
          </Menu.Item>
          {/* <Menu.SubMenu key="about" title={<span>About <DownOutlined /></span>}>
            {aboutMenu}
          </Menu.SubMenu>
          <Menu.SubMenu key="services" title={<span>Services <DownOutlined /></span>}>
            {servicesMenu}
          </Menu.SubMenu>
          <Menu.SubMenu key="fleet" title={<span>Fleet <DownOutlined /></span>}>
            {fleetMenu}
          </Menu.SubMenu> */}
          <Menu.Item key="/about">
            <Link to="/about">About us</Link>
          </Menu.Item>

          {/* <Menu.Item key="/pricing">
          <Link to="/pricing">About us</Link>
        </Menu.Item> */}
        
          {/* <Menu.Item key="/pricing">
            <Link to="/pricing">Pricing</Link>
          </Menu.Item> */}
          <Menu.Item key="/blog">
            <Link to="/blog">Blog</Link>
          </Menu.Item>
          <Menu.Item key="/contact">
            <Link to="/contact">Contact</Link>
          </Menu.Item>

          <Menu.Item key="/pricing">
          <Link to="/pricing">Pricing</Link>
        </Menu.Item>



          <Menu.Item key="ask-question">
            <Button type="primary" onClick={handleAuth} block>
              Login/Signup
            </Button>
          </Menu.Item>

          
        </Menu>
      </Drawer>
      <Modal
        title="Chat with Us"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="send" type="primary" onClick={handleOk}>
            Send
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder="Type your question here..."
        />
      </Modal>
    </Header>
  );
};

export default Navbar;
