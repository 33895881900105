import React from 'react';
import { Layout, Title, Row, Col } from 'antd';
import BenefitsSection from './BenefitsSection';
import './ContentLayout.css';
import CustomerReviews from './CustomerReviews';
import ServicesSection from './ServiceSection';
import FAQSection from './FAQSection';
import BookingProcessSection from './BookingProcessSection';
const { Content } = Layout;

const ContentLayout = () => {
  return (
    <Layout className="content-layout">
      <BenefitsSection />
      <BookingProcessSection/>
      <ServicesSection/>
      <CustomerReviews />
      <FAQSection/>
    </Layout>
  );
};

export default ContentLayout;
