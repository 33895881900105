// About.js
import React from 'react';
import TermsOfServiceContent from '../components/Common/TermsOfServiceContent';

const Terms = () => {

  return (
    <>
      <TermsOfServiceContent />
    </>
  );
};

export default Terms;
