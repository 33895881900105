import React, { useState } from 'react';
import PointsDashboard from './PointsDashboard';
import ReferralSystem from './ReferralSystem';
import Challenges from './Challenges';
import RedeemableRewards from './RedeemableRewards';
import ScratchCard from './ScratchCard';
import './RewardsDashboard.css';

const RewardsDashboard = () => {
  const [points, setPoints] = useState(150);
  const tier = 'Gold';
  const nextTierPoints = 200;
  const referralCode = 'ABC123';
  const referrals = [
    { name: 'John Doe', status: 'First Ride Completed', points: 10 },
    { name: 'Jane Doe', status: 'Invited', points: 0 },
  ];
  const challenges = [
    { name: 'Complete 5 Rides', description: 'Complete 5 rides this week', progress: 60, points: 20 },
    { name: 'Refer 3 Friends', description: 'Refer 3 friends this month', progress: 33, points: 30 },
  ];
  const rewards = [
    { name: '10% Off', description: '10% off on your next ride', points: 50 },
    { name: 'Free Ride', description: 'Get a free ride up to $10', points: 100 },
    { name: 'VIP Support', description: 'Priority customer support', points: 200 },
  ];

  const handleScratchReveal = (bonusPoints) => {
    setPoints(prevPoints => prevPoints + bonusPoints);
  };

  return (
    <div className="rewards-dashboard">
      <PointsDashboard points={points} tier={tier} nextTierPoints={nextTierPoints} />
      <ReferralSystem referralCode={referralCode} referrals={referrals} />
      <Challenges challenges={challenges} />
      <RedeemableRewards rewards={rewards} points={points} />
      {/* <ScratchCard onReveal={handleScratchReveal} /> */}
    </div>
  );
};

export default RewardsDashboard;
