import React from 'react';
import { List, Button } from 'antd';
import './RedeemableRewards.css';

const RedeemableRewards = ({ rewards, points }) => {
  return (
    <div className="redeemable-rewards">
      <h2>Upcoming Redeemable Rewards</h2>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={rewards}
        renderItem={reward => (
          <List.Item className="reward-item">
            <div className="reward-info">
              <h3>{reward.name}</h3>
              <p>{reward.description}</p>
              <p><strong>{reward.points} Points</strong></p>
            </div>
            <Button
              type="primary"
              disabled={points < reward.points}
              className="redeem-btn"
            >
              Redeem
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
};

export default RedeemableRewards;
