import React from 'react';
import { Typography, Layout } from 'antd';
import './TermsOfService.css'; // Import the CSS file

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const TermsOfServiceContent = () => {
  return (
    <Layout className="terms-layout">
      <Content className="terms-content">
        <Title level={1} className="terms-title">
          Terms of Service
        </Title>
        <Paragraph className="terms-paragraph">
          Welcome to indorecabs.com. These terms of service outline the rules and regulations for the use of our website and services.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          1. Introduction
        </Title>
        <Paragraph className="terms-paragraph">
          By accessing this website and using our services, you accept these terms and conditions in full. If you disagree with any part of these terms and conditions, do not continue to use our website or services.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          2. License to Use Website
        </Title>
        <Paragraph className="terms-paragraph">
          Unless otherwise stated, indorecabs.com owns the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may access this from indorecabs.com for your own personal use, subject to the restrictions set out in these terms and conditions.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          3. User Obligations
        </Title>
        <Paragraph className="terms-paragraph">
          As a user of our website or services, you agree to:
        </Paragraph>
        <Paragraph>
          <ul className="terms-list">
            <li>Provide accurate, current, and complete information about yourself as prompted by our registration forms.</li>
            <li>Maintain the security of your password and identification.</li>
            <li>Be responsible for all activities that occur under your account.</li>
            <li>Notify us immediately of any unauthorized use of your account or any other breach of security.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          4. Prohibited Activities
        </Title>
        <Paragraph className="terms-paragraph">
          You are expressly prohibited from:
        </Paragraph>
        <Paragraph>
          <ul className="terms-list">
            <li>Using the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.</li>
            <li>Using the website in any way that is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
            <li>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this website.</li>
            <li>Using the website to engage in any advertising or marketing without our explicit permission.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          5. Limitation of Liability
        </Title>
        <Paragraph className="terms-paragraph">
          In no event shall indorecabs.com, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise, and indorecabs.com, including its officers, directors, and employees shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          6. Governing Law & Jurisdiction
        </Title>
        <Paragraph className="terms-paragraph">
          These terms will be governed by and construed in accordance with the laws of [Your State/Country], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your State/Country] for the resolution of any disputes.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          7. Changes to These Terms
        </Title>
        <Paragraph className="terms-paragraph">
          We may revise these terms of service at any time without notice. By using this website, you are agreeing to be bound by the then-current version of these terms of service.
        </Paragraph>

        <Title level={2} className="terms-subtitle">
          8. Contact Us
        </Title>
        <Paragraph className="terms-paragraph">
          If you have any questions about these terms, please contact us at <a href="mailto:contact@indorecabs.com" className="terms-link">contact@indorecabs.com</a>.
        </Paragraph>
      </Content>
    </Layout>
  );
};

export default TermsOfServiceContent;
