// About.js
import React from 'react';
import PricingPage from '../components/Customer/PricingPage';

const Pricing = () => {
  return (
    <>
      <PricingPage />
    </>
  );
};

export default Pricing;
