import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderLayout from './HeaderLayout.js';
import './CityPage.css';
import FAQSection from './FAQSection.js';
import { getCityData } from '../../services/api.js';
import ReactMarkdown from 'react-markdown';
import { Row, Col, Card, Collapse } from 'antd';
import {
  CarOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  GiftOutlined,
  ShoppingOutlined,
  HeartOutlined,
} from '@ant-design/icons'; 

const MAX_CONTENT_LENGTH = 300;

const CityPage = () => {
  const { cityName } = useParams();
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [showFullCulture, setShowFullCulture] = useState(false);

  const { Panel } = Collapse;


  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const data = await getCityData(cityName.toLowerCase());
        setCity(data);
        setLoading(false);
      } catch (err) {
        setError('City not found');
        setLoading(false);
      }
    };

    fetchCityData();
  }, [cityName]);

  if (loading) {
    return <h2>Loading...</h2>;
  }

  if (error) {
    return <h2>{error}</h2>;
  }

  const toggleHistory = () => setShowFullHistory(!showFullHistory);
  const toggleCulture = () => setShowFullCulture(!showFullCulture);

  const renderContent = (content, isFullVisible) => {
    if (content.length <= MAX_CONTENT_LENGTH || isFullVisible) {
      return content;
    }
    return `${content.slice(0, MAX_CONTENT_LENGTH)}...`;
  };

  return (
    <div>
      <HeaderLayout />
      <div className="city-page">

        <section className="city-intro">
          <h1>{city.name} Taxi Services</h1>
          <p>{city.meta_description}</p>
        </section>

        <section className="city-history">
          <div className="topic-headings">
            <h2>History of {city.name}</h2>
          </div>
          <ReactMarkdown>{renderContent(city.history, showFullHistory)}</ReactMarkdown>
          {city.history.length > MAX_CONTENT_LENGTH && (
            <button className="cta-button" onClick={toggleHistory}>
              {showFullHistory ? 'Show Less' : 'Read More'}
            </button>
          )}
        </section>

        <section className="city-culture">
          <div className="topic-headings">
            <h2>Culture & Attractions in {city.name}</h2>
          </div>
          <ReactMarkdown>{renderContent(city.culture, showFullCulture)}</ReactMarkdown>
          {city.culture.length > MAX_CONTENT_LENGTH && (
            <button className="cta-button" onClick={toggleCulture}>
              {showFullCulture ? 'Show Less' : 'Read More'}
            </button>
          )}
        </section>

        <section className="services-section">
          <h2>Our Services in {city.name}</h2>
          <Row gutter={[16, 16]} justify="center">
            {city.services.map((service, index) => (
              <Col xs={24} sm={12} md={8} key={index}>
                <Card hoverable className="service-card">
                  <div className="icon-wrapper">
                    <CarOutlined /> {/* You can map actual icons here if you have specific ones */}
                  </div>
                  <h3>{service.name}</h3>
                  <ReactMarkdown>{service.description}</ReactMarkdown>
                  <p>Base Fare: INR {service.baseFare}</p>
                  <p>Per Km Rate: INR {service.perKmRate}</p>
                  <button className="book-now-button">Book Now</button>
                </Card>
              </Col>
            ))}
          </Row>
        </section>

        <section className="routes-section">
          <h2>Popular Routes from {city.name}</h2>
          <Collapse accordion>
            {city.popularRoutes.map((route, index) => (
              <Panel header={`${route.routeName} - ${route.distance}`} key={index}>
                <p>Estimated Time: {route.estimatedTime}</p>
                <ReactMarkdown>{route.description}</ReactMarkdown>
                <p>Fare: INR {route.fare}</p>
              </Panel>
            ))}
          </Collapse>
        </section>

        <section className="">
          <FAQSection faqs={city.faqs} />
        </section>

      </div>
    </div>
  );
};

export default CityPage;
