import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import './RoutesPage.css';
import { slugify } from '../../utils/slugify';

const RoutesPage = () => {
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Replace with actual data fetching
    const dummyRoutes = [
      { name: 'Ranchi to Bokaro cabs' },
      { name: 'Bokaro to Ranchi cabs' },
      { name: 'Indore to Omkareshwar cabs' },
      { name: 'Patna to Varanasi cabs' },
      { name: 'Kolkata to Puri cabs' },
    ];

    const routesWithSlugs = dummyRoutes.map(route => ({
      ...route,
      slug: slugify(route.name),
    }));

    setRoutes(routesWithSlugs);
    setFilteredRoutes(routesWithSlugs);
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = routes.filter(route =>
      route.name.toLowerCase().includes(value)
    );
    setFilteredRoutes(filtered);
  };

  return (
    <div className="routes-page-container">
      <header className="routes-header">
        <h1>Our Routes</h1>
        <p>Explore our popular taxi routes</p>
      </header>

      <Input
        placeholder="Search for a route..."
        value={searchTerm}
        onChange={handleSearch}
        className="route-search-bar"
        allowClear
      />

      <div className="routes-grid">
        {filteredRoutes.length > 0 ? (
          filteredRoutes.map((route) => (
            <Link key={route.slug} to={`/routes/${route.slug}`} className="route-card">
              <div className="route-card-content">
                <h3>{route.name}</h3>
              </div>
            </Link>
          ))
        ) : (
          <p className="no-routes">No routes found.</p>
        )}
      </div>
    </div>
  );
};

export default RoutesPage;
