import React from 'react';
import { Typography, Button } from 'antd';
import CountUp from 'react-countup';
import './AboutContent.css';
import BottomNav from '../Common/BottomNav';
import Breadcrumbs from '../Common/BreadCrumbs';

const { Title, Paragraph } = Typography;

const AboutContent = () => {

  const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Blog', path: '/blog', active: true },
  ];


  return (
    <div className="about-container">

      <div className="about-section">
        <div className="about-content">

          <div className="about-subtitle">Few words about Indore Cabs</div>
          <Title className="about-title">Learn about our taxi company</Title>
          <Paragraph className="about-text">
            Welcome to Indore Cabs, your premier taxi service in Indore, providing reliable and affordable transportation solutions. Whether you need a quick ride across the city or a comfortable long-distance journey, we have you covered. Our commitment to excellence ensures that every ride is not only safe and punctual but also a pleasant experience tailored to your needs.
          </Paragraph>
          <Button className="about-button" type="primary" size="large">Discover More</Button>
        </div>
        <div className="about-image-wrapper">
          <img src="http://ashik.templatepath.net/conexi-preview-files/images/resources/about-1-1.jpg" alt="Taxi Driver" className="about-image" />
          <div className="trust-badge">
            Trusted by <CountUp start={0} end={4880} duration={3}  /> happy customers
          </div>
        </div>
      </div>

      <div className="benefit-section">
        <div className="benefit-header">
          <p className="benefit-subtitle">Indore Cabs benefit list</p>
          <h2 className="benefit-title">Why choose us</h2>
        </div>
        <div className="benefit-cards">
          <div className="benefit-card">
            <div className="benefit-icon">
              {/* <img src="https://via.placeholder.com/60" alt="Safety Guarantee" /> */}
            </div>
            <h3 className="benefit-card-title">Safety Guarantee</h3>
            <p className="benefit-card-text">We prioritize your safety with thoroughly vetted and experienced drivers ensuring a secure journey every time.</p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">
              {/* <img src="https://via.placeholder.com/60" alt="DBS Cleared Drivers" /> */}
            </div>
            <h3 className="benefit-card-title">DBS Cleared Drivers</h3>
            <p className="benefit-card-text">Our drivers are DBS cleared, giving you peace of mind knowing you're in safe and trusted hands.</p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">
              {/* <img src="https://via.placeholder.com/60" alt="Free Quotation" /> */}
            </div>
            <h3 className="benefit-card-title">Free Quotation</h3>
            <p className="benefit-card-text">Get a free, no-obligation quote for any journey, whether it's a short trip across town or a long-distance travel.</p>
          </div>
        </div>
      </div>

      <div className="cta-section">
        <div className="moving-strip top-strip"></div>
        <p className="cta-subtitle">Call 24 hour service available</p>
        <Title className="cta-title">Call now and book our taxi for your next ride</Title>
        <Button className="cta-button" type="primary" size="large">Choose Your Taxi</Button>
        <div className="moving-strip bottom-strip"></div>
      </div>

    </div>
  );
};

export default AboutContent;
