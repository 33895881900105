import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Card, Row, Col, Badge, Button, message } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, SafetyOutlined } from '@ant-design/icons';
import moment from 'moment';
import hatchbackImage from '../assets/hatchback.png';
import sedanImage from '../assets/sedan.png';
import suvImage from '../assets/innova.png';
import './BookingDetailsPage.css';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../../services/api'; // Adjust the path as necessary

const { Text, Paragraph } = Typography;

const DAILY_VEHICLE_RENT_COST = 1000; // Daily rent cost for the vehicle including driver expenses

// Default response object (used when no props are passed)
const defaultResponse = {
    "phoneNumber": "8971717717", // optional, can be omitted if not needed
    "pickupLocation": "Ranchi, Jharkhand, India",
    "dropLocation": "Bokaro Steel City, Jharkhand, India",
    "itinerary": [
        "Ranchi, Jharkhand, India",
        "Bokaro Steel City, Jharkhand, India"
    ],
    "totalDistance": 112.236,
    "totalTime": "2 hours 54 mins",
    "images": {
        "hatchback": hatchbackImage,
        "sedan": sedanImage,
        "suv": suvImage
    },
    "status": "Pending",
    "_id": "66b8fd68609a8ced98f8a830",
    "createdAt": "2024-08-11T18:05:28.687Z",
    "updatedAt": "2024-08-11T18:05:28.687Z",
    "__v": 0
};

const PricingPage = ({ bookingDetails = defaultResponse }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [departureDate, setDepartureDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(null);
  const [prices, setPrices] = useState({
    hatchback: null,
    sedan: null,
    suv: null,
  });

  useEffect(() => {
    if (bookingDetails.totalCost) {
      setPrices({
        hatchback: Math.round(bookingDetails.totalCost.hatchback),
        sedan: Math.round(bookingDetails.totalCost.sedan),
        suv: Math.round(bookingDetails.totalCost.suv),
      });
    } else {
      // Set per km rates or default pricing strategy if totalCost is not available
      setPrices({
        hatchback: DAILY_VEHICLE_RENT_COST + Math.round(bookingDetails.totalDistance * 11.2), // Example calculation
        sedan: DAILY_VEHICLE_RENT_COST + Math.round(bookingDetails.totalDistance * 13.5),
        suv: DAILY_VEHICLE_RENT_COST + Math.round(bookingDetails.totalDistance * 15.8),
      });
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (returnDate && returnDate.isBefore(departureDate)) {
      message.error('Return date cannot be before the departure date.');
      setReturnDate(null);
      // Recalculate prices based on per-km or default values
      if (bookingDetails.totalCost) {
        setPrices({
          hatchback: Math.round(bookingDetails.totalCost.hatchback),
          sedan: Math.round(bookingDetails.totalCost.sedan),
          suv: Math.round(bookingDetails.totalCost.suv),
        });
      }
    } else if (returnDate) {
      const days = returnDate.diff(departureDate, 'days') + 1; // Include both departure and return days
      setPrices({
        hatchback: Math.round((prices.hatchback + DAILY_VEHICLE_RENT_COST) * days),
        sedan: Math.round((prices.sedan + DAILY_VEHICLE_RENT_COST) * days),
        suv: Math.round((prices.suv + DAILY_VEHICLE_RENT_COST) * days),
      });
    }
  }, [departureDate, returnDate]);

  const handleBooking = async (amount, name, description) => {

    console.log("Amount is : ", amount)
    const createOrderRequest = {
        'amount': amount
    }
    const order = await createOrder(createOrderRequest)


    console.log("Order Details: ", order);

    const options = {
      key: "rzp_live_OP9Fkn4pgh6AYx", // Replace with your key ID
      amount: 1 * 100, // Amount in paise
      currency: "INR",
      name: name,
      description: description,
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: function (response) {
        console.log(response)
        navigate('/payment-success', {
          state: {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
          },
        });
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      navigate('/payment-failure', {
        state: {
          errorCode: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          orderId: response.error.metadata.order_id,
          paymentId: response.error.metadata.payment_id,
        },
      });
    });

    rzp1.open();
  };

  return (
    <div className="booking-details-container">
      <div className="features-section">
        <div className="feature-item">
          <SafetyOutlined />
          <Text>Safety First</Text>
        </div>
        <div className="feature-item">
          <CheckCircleOutlined />
          <Text>Confirmed Cab</Text>
        </div>
        <div className="feature-item">
          <ClockCircleOutlined />
          <Text>Always On-time</Text>
        </div>
      </div>
      <Row gutter={16} justify="center">
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={hatchbackImage} alt="Hatchback" className="car-image" />
              </div>
              <Text className="vehicle-type">Hatchback (AC)</Text>
              <Paragraph className="vehicle-description">Swift, WagonR or Similar</Paragraph>
              
              {prices.hatchback && location.pathname !== '/pricing' && (
                <Paragraph className="total-cost">
                  Total Cost: <strong>₹ {prices.hatchback}</strong>
                </Paragraph>
              )}

              <Paragraph className="vehicle-details">
                Extra fare/Km: <strong>₹ 11.2/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
                onClick={() => handleBooking(prices.hatchback, 'Hatchback (AC)', 'Swift, WagonR or Similar')}>
                Book Now
              </Button>
            </Badge.Ribbon>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={sedanImage} alt="Sedan" className="car-image" />
              </div>
              <Text className="vehicle-type">Sedan (AC)</Text>
              <Paragraph className="vehicle-description">Dzire, Etios or Similar</Paragraph>

              {prices.sedan && location.pathname !== '/pricing' && (
                <Paragraph className="total-cost">
                  Total Cost: <strong>₹ {prices.sedan}</strong>
                </Paragraph>
              )}

              <Paragraph className="vehicle-details">
                Extra fare/Km: <strong>₹ 13.5/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
                onClick={() => handleBooking(prices.sedan, 'Sedan (AC)', 'Dzire, Etios or Similar')}>
                Book Now
              </Button>
            </Badge.Ribbon>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={suvImage} alt="SUV" className="car-image" />
              </div>
              <Text className="vehicle-type">SUV (AC)</Text>
              <Paragraph className="vehicle-description">Innova, Ertiga, Marazzo or Similar</Paragraph>

              {prices.suv && location.pathname !== '/pricing' && (
                <Paragraph className="total-cost">
                  Total Cost: <strong>₹ {prices.suv}</strong>
                </Paragraph>
              )}

              <Paragraph className="vehicle-details">
                Extra fare/Km: <strong>₹ 15.8/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
                onClick={() => handleBooking(prices.suv, 'SUV (AC)', 'Innova, Ertiga, Marazzo or Similar')}>
                Book Now
              </Button>
            </Badge.Ribbon>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PricingPage;
