import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import './PaymentSuccess.css';
import { GoogleLogin } from '@react-oauth/google'; // Import GoogleLogin
import { useDispatch } from 'react-redux'; // Import useDispatch
import { findUserByPhoneNumber, googleSignIn } from '../../services/api'; // Import googleSignIn
import { loginSuccess } from '../../redux/authSlice'; // Import loginSuccess
import { message } from 'antd'; // Import message for notifications
import { updateUser, fetchUserByEmail, fetchUserByPhoneNumber, deleteUser } from '../../services/api'
const { Title, Paragraph, Text } = Typography;

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize useDispatch
  const { paymentId, orderId, signature } = location.state || {};

// Handle Google Login Success
const responseMessage = async (response) => {
  try {
    console.log("Google response received:", response);

    // Get the Google user data
    const res = await googleSignIn(response.credential);
    console.log("Google sign-in response:", res);

    if (!res.token) throw new Error('Token is missing in the response');

    // Dispatch login success and show a success message
    dispatch(loginSuccess(res.token));
    console.log("Login successful, user token dispatched:", res.token);
    message.success('Google login successful!');
    navigate('/profile'); // Redirect to profile after successful login
  } catch (error) {
    console.error('Failed to sign in with Google:', error);
    message.error('Google login failed. Please try again.');
  }
};






  // Handle Google Login Failure
  const errorMessage = () => {
    message.error('Google login failed. Please try again.');
  };

  return (
    <div className="payment-success-container">
      <Card className="payment-success-card">
        <Row justify="center">
          <Col>
            <CheckCircleOutlined className="success-icon" />
          </Col>
        </Row>
        <Title level={2} className="success-title">Payment Successful</Title>
        <Paragraph className="success-message">
          Thank you for your payment! Your transaction has been completed successfully.
        </Paragraph>
        <div className="transaction-details">
          <Text strong>Payment ID: </Text>
          <Text>{paymentId}</Text>
          <br />
          <Text strong>Order ID: </Text>
          <Text>{orderId}</Text>
          <br />
          <Text strong>Signature: </Text>
          <Text>{signature}</Text>
        </div>
        <div className="google-login-section">
          <Paragraph className="login-prompt">
            Login with Google to take advantage of reward points, view your profile, discounts, previous rides, and more.
          </Paragraph>
          <GoogleLogin 
            onSuccess={responseMessage} 
            onError={errorMessage} 
            className="google-login-button"
          />
        </div>
        <Button type="primary" className="back-button" onClick={() => navigate('/')}>
          Go to Homepage
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
