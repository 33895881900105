import axios from 'axios';
import { jwtDecode } from 'jwt-decode';


const api = axios.create({
  // baseURL: 'https://ct-admin-server-1340377667.ap-south-1.elb.amazonaws.com',
  baseURL: 'https://api.citytaxis.in'
  // baseURL: 'http://localhost:3001'
  
});

// Customers

export const loginUser = async (credentials) => {
  const response = await api.post('/login', credentials);
  return response.data;
};

export const registerUser = async (data) => {
  console.log("Registering with data: ", data)
  const response = await api.post('/api/auth/register', data);
  return response.data;
};

export const updateUser = async (userId, updateData) => {
  console.log("Updating user: ", userId, updateData)
  const response = await api.put(`/api/users/${userId}`, updateData);
  return response.data;
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete user:', error);
    throw new Error('Failed to delete user');
  }
};

export const fetchUserByEmail = async (email) => {
  const response = await api.get(`/api/users/email/${email}`);
  return response.data;
};

export const findUserByPhoneNumber = async (phoneNumber) => {
  try {
    const response = await api.get(`/api/users/phone/${phoneNumber}`);
    return response.data; // Assume the response contains the user data if found
  } catch (error) {
    console.error('Error finding user by phone number:', error);
    throw new Error('Failed to find user by phone number');
  }
};


export const bookRide = async (bookingDetails) => {
  try {
    const response = await api.post('/api/bookings/book', bookingDetails);
    return response.data;
  } catch (error) {
    console.error('Error booking ride:', error.response ? error.response.data : error.message);
    throw new Error('Failed to book ride');
  }
};

export const getSuggestedLocations = async (query) => {
  try {
    const response = await api.get(`/api/locations/suggestions?q=${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch suggestions');
  }
};

export const getAllBookings = async () => {
  try {
    const response = await api.get('/api/bookings');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch bookings');
  }
};

export const createOrder = async (createOrderRequest) => {
  try {
    console.log("Creating order with amount: ", createOrderRequest)
    const response = await api.post('/api/razorpay/create-order', createOrderRequest);
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
  }
};


// Drivers

export const getAllDrivers = async () => {
  const response = await api.get('/api/drivers/all');
  return response.data;
};

export const addDriver = async (driver) => {
  const response = await api.post('/api/drivers/add', driver);
  return response.data;
};

export const updateDriver = async (id, driver) => {
  const response = await api.put(`/api/drivers/update/${id}`, driver);
  return response.data;
};

export const deleteDriver = async (id) => {
  const response = await api.delete(`/api/drivers/delete/${id}`);
  return response.data;
};

// Vehicles

export const getAllVehicles = async () => {
  const response = await api.get('/api/vehicles/all');
  return response.data;
};

export const addVehicle = async (driver) => {
  const response = await api.post('/api/vehicles/add', driver);
  return response.data;
};

export const updateVehicle = async (id, driver) => {
  const response = await api.put(`/api/vehicles/update/${id}`, driver);
  return response.data;
};

export const deleteVehicle = async (id) => {
  const response = await api.delete(`/api/vehicles/delete/${id}`);
  return response.data;
};


// Whatsapp

export const fetchTemplates = async () => {
  const response = await api.get('/api/whatsapp/templates');
  return response.data;
}

export const createTemplate = async () => {
  const response = await api.get('/api/whatsapp/templates');
  return response.data;
}




export const sendMessage = async (sendMessageRequest) => {
  const response = await api.post('/api/whatsapp/send', sendMessageRequest)
  return response.data;

}

export const sendTextMessage = async (sendTextMessageRequest) => {
  const response = await api.post('/api/whatsapp/sendTextMessage', sendTextMessageRequest)
  return response.data;
}

export const sendWhatsAppMessage = async (sendWhatsAppMessageRequest) => {
  const response = await api.post('/api/whatsapp/sendWhatsAppMessage', sendWhatsAppMessageRequest)
  return response.data;
}

// Add this function to your services/api.js file

export const sendTemplateToAllUsers = async (template) => {
  try {
    const response = await api.post('/api/whatsapp/sendTemplateToAllUsers', { template });
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Failed to send template to all users:', error.response ? error.response.data : error.message);
    throw new Error('Failed to send template to all users');
  }
};




export const getBillById = async (id) => {
  const response = await api.get(`/api/bookings/${id}`, id)
  return response.data;

}

// Update booking details
export const updateBookingById = async (id, updateData) => {
  try {
    const response = await api.put(`/api/bookings/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating booking info:', error);
    throw new Error('Failed to update booking info');
  }
};

export const getBookingsByUserId = async (userId) => {
  try {
    const response = await api.get(`/api/bookings/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch bookings:', error);
    throw new Error('Failed to fetch bookings');
  }
};

// Update booking details
export const updateBookingInfo = async (id, updateData) => {
  try {
    const response = await api.put(`/api/bookings/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating booking info:', error);
    throw new Error('Failed to update booking info');
  }
};



// const handleSendMessage = async () => {
//   const manualContactsList = manualContacts.split(',').map(number => number.trim()).filter(Boolean);
//   const allContacts = [...selectedContacts, ...manualContactsList];
  
//   if (allContacts.length === 0) {
//     message.error('Please select or enter at least one contact.');
//     return;
//   }

//   try {
//     await fetch('/api/whatsapp/send', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ contacts: allContacts, templateName: selectedTemplate }),
//     });
//     message.success('Messages sent successfully!');
//     setSelectedContacts([]);
//     setManualContacts('');
//     setSelectedTemplate('');
//   } catch (err) {
//     message.error('Failed to send messages');
//   }
// };


// Fetch city data by city ID
export const getCityData = async (city_id) => {
  try {
    const response = await api.get(`/api/pageData/city/${city_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching city data:', error);
    throw new Error('Failed to fetch city data');
  }
};

// Create new city data
export const createCityData = async (cityData) => {
  try {
    const response = await api.post('/api/pageData/city', cityData);
    return response.data;
  } catch (error) {
    console.error('Error creating city data:', error);
    throw new Error('Failed to create city data');
  }
};

// Update city data by city ID
export const updateCityData = async (city_id, updateData) => {
  try {
    const response = await api.put(`/api/pageData/city/${city_id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating city data:', error);
    throw new Error('Failed to update city data');
  }
};

// Delete city data by city ID
export const deleteCityData = async (city_id) => {
  try {
    const response = await api.delete(`/api/pageData/city/${city_id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting city data:', error);
    throw new Error('Failed to delete city data');
  }
};


// Fetch all blog posts from the server
export const getAllPosts = async () => {
  try {
    const response = await api.get('/api/blog/posts'); // Adjust the endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch posts');
  }
};

export const googleSignIn = async (credential) => {
  try {

    // Retrieve the user from localStorage
    const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    console.log("Stored user from localStorage:", storedUser);

    // Decode the Google token to get user information
    const userObject = jwtDecode(credential);

    console.log("UserObject: ", userObject);
    console.log("StoredUser: ", storedUser);

    // Make an API call to save the user data

    let response;

    if(storedUser!=null && storedUser.phone!=null) {
      response = await api.post('/api/users/google-signin', {
        name: userObject.name,
        email: userObject.email,
        picture: userObject.picture,
        phone: storedUser.phone
      });
    } else {
      response = await api.post('/api/users/google-signin', {
        name: userObject.name,
        email: userObject.email,
        picture: userObject.picture,
      });
    }


    return response.data; // Return the response data
  } catch (error) {
    console.error('Failed to sign in with Google:', error);
    throw new Error('Google sign-in failed');
  }
};


// const res = await api.post('/api/users/google-signin', {
//   name: userObject.name,
//   email: userObject.email,
//   picture: userObject.picture,
// });



export const getAllCitiesMetaData = async () => {
  const response = await api.get('/api/cities/metadata');
  return response.data;
};



// Fetch route details by route ID
export const fetchRouteDetailsById = async (routeId) => {
  try {
    const response = await api.get(`/api/routedetails/${routeId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching route details:', error);
    throw new Error('Failed to fetch route details');
  }
};

// Fetch all top routes
export const fetchAllTopRoutes = async () => {
  try {
    const response = await api.get('/api/toproutes');
    return response.data;
  } catch (error) {
    console.error('Error fetching top routes:', error);
    throw new Error('Failed to fetch top routes');
  }
};


export default api;
