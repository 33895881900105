import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Card, Row, Col, Badge, Button, DatePicker, message } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, SafetyOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import './BookingDetailsPage.css';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../../services/api';
import hatchbackImage from '../assets/hatchback.png';
import sedanImage from '../assets/sedan.png';
import suvImage from '../assets/innova.png';


const { Title, Paragraph, Text } = Typography;
const DAILY_VEHICLE_RENT_COST = 1000;

const BookingDetailsPage = () => {
  const location = useLocation();
  const { bookingDetails } = location.state || {};
  const navigate = useNavigate();

  const [departureDate, setDepartureDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(null);
  const [prices, setPrices] = useState(calculateInitialPrices());

  useEffect(() => {
    if (returnDate && returnDate.isBefore(departureDate)) {
      message.error('Return date cannot be before the departure date.');
      setReturnDate(null);
      setPrices(calculateInitialPrices());
    } else {
      setPrices(calculateUpdatedPrices());
    }
  }, [departureDate, returnDate]);

  function calculateInitialPrices() {
    return {
      hatchback: Math.round(bookingDetails.totalCost.hatchback),
      sedan: Math.round(bookingDetails.totalCost.sedan),
      suv: Math.round(bookingDetails.totalCost.suv),
    };
  }

  function calculateUpdatedPrices() {
    const days = returnDate ? returnDate.diff(departureDate, 'days') + 1 : 1;
    return {
      hatchback: Math.round((bookingDetails.totalCost.hatchback + DAILY_VEHICLE_RENT_COST) * days),
      sedan: Math.round((bookingDetails.totalCost.sedan + DAILY_VEHICLE_RENT_COST) * days),
      suv: Math.round((bookingDetails.totalCost.suv + DAILY_VEHICLE_RENT_COST) * days),
    };
  }

  const handleBooking = async (amount, name, description) => {
    const order = await createOrder({ amount });
    const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const { userName, userEmail, userContact } = getUserDetails(storedUser);

    const options = getRazorpayOptions(order, amount, name, description, userName, userEmail, userContact);
    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', handlePaymentFailure);
    rzp1.open();
  };

  function getUserDetails(storedUser) {
    return {
      userName: storedUser?.name || "Guest User",
      userEmail: storedUser?.email || "guest@example.com",
      userContact: storedUser?.phone || "0000000000",
    };
  }

  function getRazorpayOptions(order, amount, name, description, userName, userEmail, userContact) {
    return {
      key: "rzp_live_OP9Fkn4pgh6AYx",
      amount: amount * 100,
      currency: "INR",
      name,
      description,
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: function (response) {
        navigate('/payment-success', {
          state: {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
          },
        });
      },
      prefill: {
        name: userName,
        email: userEmail,
        contact: userContact,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
  }

  function handlePaymentFailure(response) {
    navigate('/payment-failure', {
      state: {
        errorCode: response.error.code,
        description: response.error.description,
        source: response.error.source,
        step: response.error.step,
        reason: response.error.reason,
        orderId: response.error.metadata.order_id,
        paymentId: response.error.metadata.payment_id,
      },
    });
  }

  function handleDepartureDateChange(date) {
    if (returnDate && date.isAfter(returnDate)) {
      message.error('Departure date cannot be after the return date.');
      return;
    }
    setDepartureDate(date);
  }

  function handleReturnDateChange(date) {
    if (date && date.isBefore(departureDate)) {
      message.error('Return date cannot be before the departure date.');
      return;
    }
    setReturnDate(date);
  }

  const renderVehicleCard = (imageSrc, price, vehicleType, vehicleDescription, vehicleDetails, amount, name, description) => (
    <Col xs={24} sm={24} md={8} lg={8}>
      <Card className="vehicle-card">
        <Badge.Ribbon text="50% OFF" color="red">
          <div className="vehicle-image">
            <img src={imageSrc} alt={vehicleType} className="car-image" />
          </div>
          <Title level={3} className="price-title">₹ {price}</Title>
          <Text className="vehicle-type">{vehicleType}</Text>
          <Paragraph className="vehicle-description">{vehicleDescription}</Paragraph>
          <Paragraph className="vehicle-details">
            <strong>Included Km:</strong> 250 Km<br />
            <strong>Extra fare/Km:</strong> ₹11.2/Km<br />
            <strong>Driver Charges:</strong> Included<br />
            <strong>Night Charges:</strong> Included<br />
            <a href="#">Other Terms</a>
          </Paragraph>
          <Button
            type="primary"
            block
            className="book-now-button"
            onClick={() => handleBooking(amount, name, description)}
          >
            Book Now
          </Button>
        </Badge.Ribbon>
      </Card>
    </Col>
  );
  

  if (!bookingDetails) {
    return <div>No booking details available.</div>;
  }

  return (
    <div className="booking-details-container">
      <div className="header">
        <ArrowLeftOutlined className="back-icon" onClick={() => navigate('/')} />
        <div className="itinerary-text">
          <h5>{bookingDetails.itinerary[0]}</h5>
          {/* <p>&rarr;</p> */}
          <p>to</p>
          <h5>{bookingDetails.itinerary[1]}</h5>
        </div>
        <div className="trip-type">{returnDate ? 'Round Trip' : 'One Way'}</div>
      </div>

      <div className="date-section">
        <div className="date-item">
          <Title level={5}>Departure</Title>
          <DatePicker
            value={departureDate}
            onChange={handleDepartureDateChange}
            className="booking-date-picker"
          />
        </div>
        <div className="date-item">
          <Title level={5}>Return</Title>
          <DatePicker
            value={returnDate}
            onChange={handleReturnDateChange}
            className="booking-date-picker"
          />
        </div>
      </div>

      <div className="features-section">
        <div className="feature-item">
          <SafetyOutlined />
          <Text>Safety First</Text>
        </div>
        <div className="feature-item">
          <CheckCircleOutlined />
          <Text>Confirmed Cab</Text>
        </div>
        <div className="feature-item">
          <ClockCircleOutlined />
          <Text>Always On-time</Text>
        </div>
      </div>

      <Row gutter={16} justify="center">
        {renderVehicleCard(
          hatchbackImage,
          prices.hatchback,
          'Hatchback (AC)',
          'Swift, WagonR or Similar',
          `Included Km: <strong>250 Km</strong><br />
          Extra fare/Km: <strong>₹ 11.2/Km</strong><br />
          Driver Charges: <strong>Included</strong><br />
          Night Charges: <strong>Included</strong><br />
          <a href="#">Other Terms</a>`,
          prices.hatchback,
          'Hatchback (AC)',
          'Pay 200 now and the rest can be paid to driver. Swift, WagonR or Similar'
        )}
        {renderVehicleCard(
          sedanImage,
          prices.sedan,
          'Sedan (AC)',
          'Dzire, Etios or Similar',
          `Included Km: <strong>250 Km</strong><br />
          Extra fare/Km: <strong>₹ 11.2/Km</strong><br />
          Driver Charges: <strong>Included</strong><br />
          Night Charges: <strong>Included</strong><br />
          <a href="#">Other Terms</a>`,
          prices.sedan,
          'Sedan (AC)',
          'Pay 200 now and the rest can be paid to driver. Dzire, Etios or Similar'
        )}
        {renderVehicleCard(
          suvImage,
          prices.suv,
          'SUV (AC)',
          'Innova, Ertiga, Marazzo or Similar',
          `Included Km: <strong>250 Km</strong><br />
          Extra fare/Km: <strong>₹ 15.8/Km</strong><br />
          Driver Charges: <strong>Included</strong><br />
          Night Charges: <strong>Included</strong><br />
          <a href="#">Other Terms</a>`,
          prices.suv,
          'SUV (AC) - Booking Advance',
          'Pay 200 now and the rest can be paid to driver. Innova, WagonR or Similar'
        )}
      </Row>
    </div>
  );
};

export default BookingDetailsPage;
