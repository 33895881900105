import React from 'react';
import { Typography, Button, Input, Form, Card, Row, Col, Space } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './ContactContent.css';

const { Title, Paragraph, Text } = Typography;

const ContactContent = () => {
  return (
    <div className="contact-container">
      {/* Header Section */}
      <div className="header-section">
        <Title level={2} className="header-title">
          Get in Touch
        </Title>
        <Paragraph className="header-paragraph">
          We'd love to hear from you! Whether you have a question, feedback, or just want to say hello,
          feel free to contact us using the form below.
        </Paragraph>
      </div>

      {/* Contact Form and Details Section */}
      <div className="content-section">
        <Row gutter={[32, 32]}>
          {/* Contact Form */}
          <Col xs={24} md={12}>
            <Card className="form-card">
              <Form
                layout="vertical"
                name="contact-form"
                className="contact-form"
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter your name' }]}
                >
                  <Input placeholder="Your Name" />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter a valid email' },
                  ]}
                >
                  <Input placeholder="Your Email" />
                </Form.Item>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    { required: true, message: 'Please enter your phone number' },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Please enter a valid 10-digit phone number',
                    },
                  ]}
                >
                  <Input placeholder="Your Phone Number" />
                </Form.Item>
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[{ required: true, message: 'Please enter your message' }]}
                >
                  <Input.TextArea rows={4} placeholder="Your Message" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>

          {/* Contact Details */}
          <Col xs={24} md={12}>
            <Card className="details-card">
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div className="contact-detail">
                  <MailOutlined className="detail-icon" />
                  <a href="mailto:contact@indorecabs.com" className="detail-text">
                    contact@indorecabs.com
                  </a>
                </div>
                <div className="contact-detail">
                  <PhoneOutlined className="detail-icon" />
                  <a href="tel:+919632833382" className="detail-text">
                    +91-9632833382
                  </a>
                </div>
                <div className="contact-detail">
                  <WhatsAppOutlined className="detail-icon" />
                  <a href="https://wa.me/+919632833382" className="detail-text">
                    +91-9632833382
                  </a>
                </div>
                <div className="contact-detail">
                  <EnvironmentOutlined className="detail-icon" />
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=89+Sagar+Vihar+Colony+Sukhliya+Indore+MP+452010"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="detail-text"
                  >
                    89, Sagar Vihar Colony, Sukhliya, Indore, MP, 452010
                  </a>
                </div>
                <div className="contact-detail">
                  <a
                    href="https://g.page/r/CSayYhDFB5miEBM/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="detail-text link-button"
                  >
                    View Google Business Profile
                  </a>
                </div>
              </Space>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Google Map Section */}
      <div className="map-section">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerClassName="map-container"
            center={{ lat: 22.763094452379555, lng: 75.86491615337296 }}
            zoom={15}
          >
            <Marker
              position={{ lat: 22.763094452379555, lng: 75.86491615337296 }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default ContactContent;
