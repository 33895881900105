import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Row, Col } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import './PaymentFailure.css';

const { Title, Paragraph, Text } = Typography;

const PaymentFailure = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { errorCode, description, source, step, reason, orderId, paymentId } = location.state || {};

  return (
    <div className="payment-failure-container">
      <Card className="payment-failure-card">
        <Row justify="center">
          <Col>
            <CloseCircleOutlined className="failure-icon" />
          </Col>
        </Row>
        <Title level={2} className="failure-title">Payment Failed</Title>
        <Paragraph className="failure-message">
          Unfortunately, your payment could not be processed. Please try again or contact support if the issue persists.
        </Paragraph>
        <div className="transaction-details">
          <Text strong>Error Code: </Text>
          <Text>{errorCode}</Text>
          <br />
          <Text strong>Description: </Text>
          <Text>{description}</Text>
          <br />
          <Text strong>Source: </Text>
          <Text>{source}</Text>
          <br />
          <Text strong>Step: </Text>
          <Text>{step}</Text>
          <br />
          <Text strong>Reason: </Text>
          <Text>{reason}</Text>
          <br />
          <Text strong>Order ID: </Text>
          <Text>{orderId}</Text>
          <br />
          <Text strong>Payment ID: </Text>
          <Text>{paymentId}</Text>
        </div>
        <Button type="primary" className="retry-button" onClick={() => navigate('/retry-payment')}>
          Retry Payment
        </Button>
        <Button className="contact-support-button" onClick={() => navigate('/contact-support')}>
          Contact Support
        </Button>
      </Card>
    </div>
  );
};

export default PaymentFailure;
