import React from 'react';
import { Button, Input, List } from 'antd';
import './ReferralSystem.css';

const ReferralSystem = ({ referralCode, referrals }) => {
  return (
    <div className="referral-system">
      <h2>Refer a Friend</h2>
      <p>Share your referral code to earn points when your friends complete their first ride.</p>
      <Input addonBefore="Your Code" defaultValue={referralCode} disabled />
      <Button type="primary" className="copy-code-btn">Copy Code</Button>
      <h3>My Referrals</h3>
      <List
        dataSource={referrals}
        renderItem={referral => (
          <List.Item className="referral-item">
            <p>{referral.name}</p>
            <p>Status: {referral.status}</p>
            <p>Points Earned: {referral.points}</p>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ReferralSystem;
