import React from 'react';
import Pagination from 'react-js-pagination';
import './PaginationComponent.css';

const PaginationComponent = ({ activePage, itemsCountPerPage, totalItemsCount, onChange }) => {
  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={5}
      onChange={onChange}
      innerClass="pagination"
      itemClass="page-item"
      linkClass="page-link"
    />
  );
};

export default PaginationComponent;
