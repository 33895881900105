import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import { SafetyCertificateOutlined, MobileOutlined, ClockCircleOutlined } from '@ant-design/icons'; // Importing Ant Design icons
import './BenefitsSection.css'

const { Title, Paragraph } = Typography;

const benefits = [
  {
    title: 'Safety',
    description: 'Your safety is our top priority. All our drivers are trained professionals, and our vehicles are regularly inspected to ensure they meet safety standards.',
    icon: <SafetyCertificateOutlined />, // Icon for Safety
  },
  {
    title: 'Convenience',
    description: 'Experience complete luxury and comfort by booking with us and being informed at each step of the process and after. We offer best in class customer experience.',
    icon: <MobileOutlined />, // Icon for Convenience
  },
  {
    title: 'Reliability',
    description: 'We provide reliable service with timely pickups and drop-offs. Track your ride in real-time and get updates on your driver\'s location.',
    icon: <ClockCircleOutlined />, // Icon for Reliability
  },
];

const BenefitsSection = () => {
  return (
    <div className="benefits-section">
      <Title level={3} id="whychooseustitle" className="benefits-title">Why Choose Us?</Title>
      <Row gutter={[16, 16]} justify="center">
        {benefits.map((benefit, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card hoverable className="benefit-card">
              <div className="icon-wrapper">
                {benefit.icon}
              </div>
              <Title level={4} className="benefit-title">{benefit.title}</Title>
              <Paragraph>{benefit.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BenefitsSection;
