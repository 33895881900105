import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import BookingDetailsPage from './components/Customer/BookingDetailsPage';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './components/Common/Login';
import Register from './components/Common/Register';
import Navbar from './components/Customer/Navbar';
import AppFooter from './components/Common/Footer';
import Home from './components/Customer/Home';
import Pricing from './pages/Pricing';
import CityPage from './components/Customer/CityPage';
import BottomNav from './components/Common/BottomNav';
import UserProfile from './components/Customer/Profile/UserProfile';
import PastRides from './components/Customer/PastRides';
import Support from './components/Customer/Support';
import RewardsDashboard from './components/Customer/RewardDashboard';
import BlogPage from './components/Blog/BlogPage';
import BlogPostDetail from './components/Blog/BlogPostDetail';
import Blog from './components/Blog/Blog';
import Breadcrumbs from './components/Common/BreadCrumbs';
import PrivateRoute from './components/Common/PrivateRoute';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { loginSuccess, logout } from './redux/authSlice';
import PaymentSuccess from './components/Customer/PaymentSuccess';
import PaymentFailure from './components/Customer/PaymentFailure';
import FAQ from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import CitiesPage from './pages/Cities/Cities';
import RoutesPage from './pages/Routes/Routes';
import RouteDetailPage from './pages/Routes/RouteDetailPage';
import ReactGA from 'react-ga';



const TRACKING_ID = "G-M6H5TB3SJ3"; // Your Tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  useEffect(() => {
    const checkSession = () => {
      const token = localStorage.getItem('authToken');
      const loginTime = localStorage.getItem('loginTime');

      if (token && loginTime) {
        const elapsed = Date.now() - parseInt(loginTime, 10);
        const sessionDuration = 1800 * 1000; // 30 minutes

        if (elapsed < sessionDuration) {
          dispatch(loginSuccess(token));
        } else {
          message.warning('Session expired. Please log in again.');
          dispatch(logout());
          navigate('/login');
        }
      }
    };

    checkSession();
    const interval = setInterval(checkSession, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, [dispatch, navigate]);

  return (
    <>
      <Navbar className="mobile-navbar" />
      <Breadcrumbs />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<PrivateRoute />}>
          <Route path="/profile" element={<UserProfile />} />
        </Route>
        {/* <Route element={<PrivateRoute />}> */}
          <Route path="/support" element={<Support/>} />
        {/* </Route> */}

        {/* <Route element={<PrivateRoute />}> */}
          <Route path="/rides" element={<PastRides/>} />
        {/* </Route> */}

        {/* <Route path="/support" element={Support} /> */}
        {/* <Route path="/rides" element={PastRides}/> */}
        <Route path="/rewards" element={RewardsDashboard} />
        <Route path="/booking-details" element={<BookingDetailsPage/>} />
        <Route path="/cities/:cityName" element={<CityPage />} />
        <Route path="/blog/*" element={<Blog />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cities" element={<CitiesPage />} />
        <Route path="/routes" element={<RoutesPage />} />
        {/* <Route path="/routes/:routeSlug" element={<RouteDetailPage />} /> */}
        <Route path="/routes/:routeId" element={<RouteDetailPage/>} />



      </Routes>
      <BottomNav />
      <AppFooter />
    </>
  );
}

export default App;
