import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((item) => item);

  // Do not render breadcrumbs on the homepage
  if (location.pathname === '/') {
    return null;
  }

  return (
    <nav aria-label="breadcrumb" className="breadcrumbs">
      <ol className="breadcrumb-list">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return (
            <li key={to} className={`breadcrumb-item ${isLast ? 'active' : ''}`}>
              {isLast ? (
                value.charAt(0).toUpperCase() + value.slice(1)
              ) : (
                <Link to={to}>{value.charAt(0).toUpperCase() + value.slice(1)}</Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
