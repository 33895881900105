import React from 'react';
import HeaderLayout from './HeaderLayout';
import ContentLayout from './ContentLayout';
import './Home.css'
import ServicesSection from './ServiceSection';


const Home = () => {
  const handleBooking = async (values) => {
    try {
      // Perform the booking action
      console.log('Booking values:', values);
    } catch (error) {
      console.error('Failed to book ride', error);
    }
  };

  return (
    <div className='home-layout'>
      <HeaderLayout/>
      <ContentLayout />
    </div>
  );
};

export default Home;
