import React from 'react';
import { Collapse, Typography } from 'antd';
import './FAQSection.css';

const { Panel } = Collapse;
const { Title } = Typography;

const defaultFAQs = [
  {
    key: "1",
    question: "What services do you offer?",
    answer: "We offer a comprehensive range of transportation services including airport transfers, outstation taxis, city tours, corporate travel services, special events transportation, shopping trips, and healthcare visits."
  },
  {
    key: "2",
    question: "How do I book a taxi for an airport transfer?",
    answer: "You can easily book an airport transfer taxi through our online booking form, by calling us directly, or via WhatsApp. We ensure timely pickups and comfortable rides to and from the airport."
  },
  {
    key: "3",
    question: "What payment methods do you accept?",
    answer: "We accept all major credit and debit cards, online payment options like PayPal, and digital wallets. Our secure payment process makes booking outstation taxis, airport transfers, and other services hassle-free."
  },
  {
    key: "4",
    question: "Do you offer airport taxi services?",
    answer: "Yes, we specialize in 24/7 airport taxi services, providing reliable and punctual transfers to ensure you catch your flight on time. Our airport taxis are well-maintained and driven by professional chauffeurs.",
    quote: "I always rely on this service for my airport transfers. They are prompt and professional, making my trips stress-free! - Harish Tiwari, Frequent Traveler"
  },
  {
    key: "5",
    question: "Can I book an outstation taxi for a round trip?",
    answer: "Absolutely! Our outstation taxi services are available for both one-way and round trips. Whether you're planning a weekend getaway or a business trip, our outstation taxis offer comfort and reliability."
  },
  {
    key: "6",
    question: "Are your taxis available for city tours?",
    answer: "Yes, our city tour taxis are perfect for exploring local attractions. We offer guided city tours for individuals and groups, making sure you experience the best the city has to offer."
  },
  {
    key: "7",
    question: "What are your rates for airport transfers?",
    answer: "Our rates for airport transfers are competitive and depend on the distance and vehicle type. You can get an exact quote by entering your details in our booking form."
  },
  {
    key: "8",
    question: "How can I modify or cancel my booking?",
    answer: "You can modify or cancel your booking through our website or by contacting our customer support. Please refer to our cancellation policy for more details."
  },
  {
    key: "9",
    question: "Is there a surcharge for late-night airport taxis?",
    answer: "We do not charge any additional fees for late-night airport taxi services. Our pricing remains transparent, regardless of the time of day."
  },
  {
    key: "10",
    question: "What vehicles are available for outstation taxis?",
    answer: "We offer a variety of vehicles for outstation taxis, including sedans, SUVs, and luxury cars. You can choose the vehicle that best suits your needs and preferences."
  }
];

const FAQSection = ({ faqs = defaultFAQs }) => {
  return (
    <div className="faq-section">
      <Title level={3} className="faq-title">Frequently Asked Questions</Title>
      <Collapse accordion>
        {faqs.map(faq => (
          <Panel header={faq.question} key={faq.key}>
            <p>{faq.answer}</p>
            {faq.quote && <blockquote>{faq.quote}</blockquote>}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQSection;
