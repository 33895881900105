import React, { useEffect } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { loginUser, loginSuccess } from '../../redux/authSlice';
import { googleSignIn } from '../../services/api';
import './Login.css';

const { Title } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(state => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile');
    }
  }, [isLoggedIn, navigate]);

  const onFinish = async (values) => {
    try {
      const result = await dispatch(loginUser(values)).unwrap();
      if (result) {
        dispatch(loginSuccess(result));
        message.success('Login successful!');
      } else {
        message.error('Failed to login. Please check your credentials.');
      }
    } catch (error) {
      console.error('Failed to login:', error);
      message.error('Failed to login. Please check your credentials.');
    }
  };

  const responseMessage = async (response) => {
    try {
      const res = await googleSignIn(response.credential);
      if (!res.token) throw new Error('Token is missing in the response');
      localStorage.setItem('authToken', res.token);
      localStorage.setItem('user', JSON.stringify(res.user));
      dispatch(loginSuccess(res.token));
      message.success('Google login successful!');
      console.log("Google response: ", res)
      navigate('/profile');
    } catch (error) {
      console.error('Failed to sign in with Google:', error);
      message.error('Google login failed. Please try again.');
    }
  };

  const errorMessage = () => {
    message.error('Google login failed. Please try again.');
  };

  return (
    <div className="login-container">
      <Title level={2} className="login-title">Login to Your Account</Title>
      <div className="google-login-container">
        <GoogleLogin 
          onSuccess={responseMessage} 
          onError={errorMessage} 
          className="google-login-button"
        />
      </div>
      <Form
        name="login"
        onFinish={onFinish}
        className="login-form"
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block className="login-button">Login</Button>
        </Form.Item>
      </Form>
      <p className="register-link">
        Don't have an account? <Link to="/register">Register now</Link>
      </p>
    </div>
  );
};

export default Login;
