import React from 'react';
import { List, Progress } from 'antd';
import './Challenges.css';

const Challenges = ({ challenges }) => {
  return (
    <div className="challenges">
      <h2>Challenges</h2>
      <List
        dataSource={challenges}
        renderItem={challenge => (
          <List.Item className="challenge-item">
            <div className="challenge-info">
              <h3>{challenge.name}</h3>
              <p>{challenge.description}</p>
            </div>
            <div className="challenge-progress">
              <Progress percent={challenge.progress} status="active" />
              <p>{challenge.points} points</p>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Challenges;
