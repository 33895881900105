import React, { useState, useEffect } from 'react';
import { List, Card, Tag, Row, Col, Tooltip, Pagination, Space } from 'antd';
import { CarOutlined, CalendarOutlined, DollarOutlined, StarOutlined, CheckCircleOutlined, CloseCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import './PastRides.css';
import { getBookingsByUserId } from '../../services/api';

const PastRides = () => {
  const [rides, setRides] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  useEffect(() => {
    const fetchRides = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user')); // Assuming the user is stored in local storage
        const data = await getBookingsByUserId(user._id); // Fetch bookings by user ID
        setRides(data);
      } catch (error) {
        console.error('Failed to fetch rides:', error);
      }
    };

    fetchRides();
  }, []);

  const getStatusIcon = (status) => {
    if (status === 'Trip Completed') {
      return <CheckCircleOutlined style={{ color: 'green' }} />;
    } else if (status === 'Trip Canceled') {
      return <CloseCircleOutlined style={{ color: 'red' }} />;
    }
    return null;
  };

  const paginatedRides = rides.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="past-rides">
      <h2 className="page-title">Past Rides</h2>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={paginatedRides}
        renderItem={ride => (
          <Card className={`ride-card ${ride.status.toLowerCase().replace(' ', '-')}`} hoverable key={ride._id}>
            <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={16}>
                <div className="ride-header">
                  <h3 className="ride-date">
                    <CalendarOutlined /> {ride.createdAt.split('T')[0]}
                  </h3>
                  <Tag icon={getStatusIcon(ride.status)} color={ride.status === 'Trip Completed' ? 'green' : 'red'} className="ride-status">
                    {ride.status}
                  </Tag>
                </div>
                <Space direction="vertical" size="small" className="ride-details">
                  <div className="ride-location">
                    <Tooltip title="Pickup Location">
                      <Tag icon={<EnvironmentOutlined />} color="blue">{ride.pickupLocation}</Tag>
                    </Tooltip>
                    <Tooltip title="Drop Location">
                      <Tag icon={<EnvironmentOutlined />} color="red">{ride.dropLocation}</Tag>
                    </Tooltip>
                  </div>
                  <div className="ride-info">
                    <Tooltip title="Distance">
                      <Tag icon={<CarOutlined />} color="blue">{ride.totalDistance} km</Tag>
                    </Tooltip>
                    <Tooltip title="Fare">
                      <Tag icon={<DollarOutlined />} color="green">${ride.totalCost.hatchback}</Tag>
                    </Tooltip>
                    <Tooltip title="Points Earned">
                      <Tag icon={<StarOutlined />} color="orange">{ride.points} points</Tag>
                    </Tooltip>
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={rides.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ textAlign: 'center', marginTop: '20px' }}
      />
    </div>
  );
};

export default PastRides;
