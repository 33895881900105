import React from 'react';
import { Tag } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';

const ItineraryDisplay = ({ itinerary, removeStop, setPickupLocation, setDropLocation }) => {
  const handleRemove = (index) => {
    const newItinerary = itinerary.filter((_, i) => i !== index);
    removeStop(newItinerary);
    
    // Update pickup and drop locations if necessary
    if (index === 0 && newItinerary.length > 0) {
      setPickupLocation(newItinerary[0]);
    }
    if (index === itinerary.length - 1 && newItinerary.length > 0) {
      setDropLocation(newItinerary[newItinerary.length - 1]);
    }
  };

  return (
    <div className="itinerary-container">
      {itinerary.map((stop, index) => (
        <React.Fragment key={index}>
          <Tag
            closable
            onClose={(e) => {
              e.preventDefault();
              handleRemove(index);
            }}
            className="stop-tag"
            closeIcon={<CloseOutlined />}
          >
            {stop}
          </Tag>
          <br></br>
          <br></br>
          {index < itinerary.length - 1 && <ArrowRightOutlined className="arrow-icon" />}

        </React.Fragment>
      ))}
    </div>
  );
};

export default ItineraryDisplay;
