import React from 'react';
import { Layout, Typography, Row, Col, Space } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, PhoneOutlined, EnvironmentOutlined, GoogleOutlined } from '@ant-design/icons';
import './Footer.css'; // Import the new CSS file

const { Footer } = Layout;
const { Title, Link, Paragraph } = Typography;

const AppFooter = () => {
  return (
    <Footer className="home-footer">
      <div className="footer-content">
        <div className="footer-column">
          <Title level={5} className="footer-title">Company</Title>
          <Space direction="vertical">
            <Link href="/about">About Us</Link>
            <Link href="/contact">Contact</Link>
          </Space>
        </div>
        <div className="footer-column">
          <Title level={5} className="footer-title">Support</Title>
          <Space direction="vertical">
            <Link href="/faq">FAQ</Link>
            <Link href="/support">Help Center</Link>
            <Link href="/terms">Terms of Service</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </Space>
        </div>

        <div className="footer-column">
          <Title level={5} className="footer-title">Cities</Title>
          <Space direction="vertical">
            <Link href="/cities/ranchi">Ranchi</Link>
            <Link href="/cities/bokaro">Bokaro</Link>
            <Link href="/cities/indore">Indore</Link>
            <Link href="/cities/patna">Patna</Link>
            <Link href="/cities/kolkata">Kolkata</Link>
            <Link href="/cities" style={{ color: '#ffd700' }}>See More Cities</Link>
          </Space>
        </div>

        <div className="footer-column">
          <Title level={5} className="footer-title">Routes</Title>
          <Space direction="vertical">
            <Link href="/routes/ranchi-to-bokaro-cabs">Ranchi to Bokaro</Link>
            <Link href="/routes/bokaro-to-ranchi-cabs">Bokaro to Ranchi</Link>
            <Link href="/routes/indore-to-omkareshwar-cabs">Indore to Omkareshwar</Link>
            <Link href="/routes/patna-to-varanasi-cabs">Patna to Varanasi</Link>
            <Link href="/routes/kolkata-to-puri-cabs">Kolkata to Puri</Link>
            <Link href="/routes" style={{ color: '#ffd700' }}>See More Routes</Link>
          </Space>
        </div>

        <div className="footer-column">
          <Title level={5} className="footer-title">Follow Us</Title>
          <Space direction="vertical">
            <Link href="#facebook">
              <FacebookOutlined style={{ color: '#3b5998', fontSize: '20px' }} /> Facebook
            </Link>
            <Link href="#twitter">
              <TwitterOutlined style={{ color: '#00acee', fontSize: '20px' }} /> Twitter
            </Link>
            <Link href="#instagram">
              <InstagramOutlined style={{ color: '#C13584', fontSize: '20px' }} /> Instagram
            </Link>
            <Link href="#linkedin">
              <LinkedinOutlined style={{ color: '#0077b5', fontSize: '20px' }} /> LinkedIn
            </Link>
          </Space>
        </div>

        <div className="footer-column">
          <Title level={5} className="footer-title">Contact Us</Title>
          <Space direction="vertical" size="large">
            <Link href="mailto:contact@indorecabs.com" style={{ whiteSpace: 'nowrap' }}>
              <MailOutlined style={{ color: '#ffd700', fontSize: '20px' }} /> contact@indorecabs.com
            </Link>
            <Link href="tel:+91-9632833382" style={{ whiteSpace: 'nowrap' }}>
              <PhoneOutlined style={{ color: '#ffd700', fontSize: '20px' }} /> +91-9632833382
            </Link>
            <Link href="https://www.google.com/maps/search/?api=1&query=89+Sagar+Vihar+Colony+Sukhliya+Indore+MP+452010" target="_blank" rel="noopener noreferrer" style={{ whiteSpace: 'nowrap' }}>
              <EnvironmentOutlined style={{ color: '#ffd700', fontSize: '20px' }} /> 89, Sagar Vihar Colony, <><br/></>
              Sukhliya, Indore, MP, 452010
            </Link>
            <Link href="https://g.page/r/CSayYhDFB5miEBM/" target="_blank" rel="noopener noreferrer" style={{ whiteSpace: 'nowrap' }}>
              <GoogleOutlined style={{ color: '#ffd700', fontSize: '20px' }} /> Google Business Profile
            </Link>
          </Space>
        </div>
      </div>

      <Row justify="center" className="footer-bottom">
        <Col>
          <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
            &copy; {new Date().getFullYear()} CityTaxis. All rights reserved.
          </Paragraph>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
