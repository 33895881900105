import React from 'react';
import { Progress, Tooltip } from 'antd';
import './PointsDashboard.css';

const PointsDashboard = ({ points, tier, nextTierPoints }) => {
  const progressPercent = (points / nextTierPoints) * 100;

  return (
    <div className="points-dashboard">
      <h2>My Points</h2>
      <div className="points-info">
        <p>Current Points: <span className="points-value">{points}</span></p>
        <p>Tier: <span className="tier-value">{tier}</span></p>
      </div>
      <Tooltip title={`${points}/${nextTierPoints} points`}>
        <Progress percent={progressPercent} status="active" />
      </Tooltip>
      <p className="next-tier-info">You need {nextTierPoints - points} more points to reach the next tier.</p>
    </div>
  );
};

export default PointsDashboard;
