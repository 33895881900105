import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './BlogPostDetail.css';
import { Helmet } from 'react-helmet';
import { Breadcrumb, Input, Button, List, Form, message, Row, Col, Tag, Divider } from 'antd';
import Sidebar from './Sidebar';  
import { useBlog } from './BlogContext';

const BlogPostDetail = () => {
  const { title } = useParams();  // Get the slug from the URL
  const { posts } = useBlog();  // Use posts from the provider


  console.log(posts)

  // Find the post by slug
  const post = posts.find(post => post.slug === title);

  console.log("Post is ", post.slug)

  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch comments only if the post is found


  const handleCommentSubmit = async () => {
    if (!commentText || !authorName) {
      message.error('Please fill in both fields.');
      return;
    }

    setLoading(true);

    const newComment = {
      postId: post._id,
      author: authorName,
      text: commentText,
    };

    try {
      const response = await fetch('/api/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newComment),
      });

      if (!response.ok) {
        throw new Error('Failed to submit comment');
      }

      const savedComment = await response.json();
      setComments([...comments, savedComment]);
      setCommentText('');
      setAuthorName('');
      message.success('Comment submitted successfully!');
    } catch (error) {
      console.error('Error submitting comment:', error);
      message.error('Failed to submit comment.');
    } finally {
      setLoading(false);
    }
  };

  // If the post is not found, return early
  if (!post) {
    return <div>Post not found</div>;
  }

  const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Blog', path: '/blog', active: true },
  ];

  const tags = post.tags || [];
  const estimatedReadingTime = Math.ceil(post.content.split(' ').length / 200); // 200 words per minute

  return (
    <div className="blog-post-detail-container">
      {/* SEO Metadata */}
      <Helmet>
        <title>{post.title} | My Blog</title>
        <meta name="description" content={post.summary} />
        <link rel="canonical" href={`https://yourdomain.com/blog/${title}`} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.summary} />
        <meta property="og:image" content={post.image} />
        <meta property="og:url" content={`https://yourdomain.com/blog/${title}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.summary} />
        <meta name="twitter:image" content={post.image} />
      </Helmet>

      <Breadcrumb crumbs={crumbs} />

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <div className="featured-image" style={{ backgroundImage: `url(${post.image})` }}></div>
          <div className="blog-post-detail">
            <h1 className="post-title">{post.title}</h1>
            <p className="post-date">
              Published on {new Date(post.date).toLocaleDateString()} • {estimatedReadingTime} min read
            </p>

            <div className="tags">
              {tags.map(tag => (
                <Tag color="gold" key={tag}>
                  {tag}
                </Tag>
              ))}
            </div>

            <div className="post-content">{post.content}</div>

            <Divider />

            <div className="post-author">
              <img src={post.author.image} alt={post.author.name} className="author-image" />
              <div>
                <h3>{post.author.name}</h3>
                <p>{post.author.bio}</p>
                <div className="author-social-links">
                  <a href={`https://twitter.com/${post.author.twitter}`} target="_blank" rel="noopener noreferrer">Twitter</a> | 
                  <a href={`https://linkedin.com/in/${post.author.linkedin}`} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>
              </div>
            </div>

            {/* Post Navigation */}
            <div className="post-navigation">
              <Link to={`/blog/${posts[posts.indexOf(post) - 1]?.slug}`} className="prev-post">← Previous Post</Link>
              <Link to={`/blog/${posts[posts.indexOf(post) + 1]?.slug}`} className="next-post">Next Post →</Link>
            </div>

            <Divider />

            {/* Social Share */}
            <div className="social-share">
              <Button className="share-button">Share on Facebook</Button>
              <Button className="share-button">Share on Twitter</Button>
              <Button className="share-button">Share on LinkedIn</Button>
            </div>

            {/* Related Posts */}
            <div className="related-posts">
              <h2>Related Posts</h2>
              <div className="related-post">
                <a href={`/blog/${posts[0].slug}`}>How to Choose the Best Cab Service</a>
              </div>
              <div className="related-post">
                {/* <a href={`/blog/${posts[2].slug}`}>The Future of Mobility</a> */}
              </div>
            </div>

            {/* Comments Section */}
            <div className="comments-section">
              <h2>Comments</h2>
              <List
                dataSource={comments}
                renderItem={(comment) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<b>{comment.author}</b>}
                      description={comment.text}
                    />
                    <span>{new Date(comment.date).toLocaleDateString()}</span>
                  </List.Item>
                )}
                locale={{ emptyText: <div><img src="empty-icon.png" alt="No comments" /> No comments yet.</div> }}
              />
              <Form layout="vertical" style={{ marginTop: '20px' }}>
                <Form.Item label="Your Name">
                  <Input
                    placeholder="Enter your name"
                    value={authorName}
                    onChange={(e) => setAuthorName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Your Comment">
                  <Input.TextArea
                    rows={4}
                    placeholder="Write your comment here..."
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleCommentSubmit} loading={loading}>
                    Submit Comment
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <Sidebar />  {/* Sidebar added here */}
        </Col>
      </Row>
    </div>
  );
};

export default BlogPostDetail;
