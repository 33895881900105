import { createSlice } from '@reduxjs/toolkit';

const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    currentBooking: null,
  },
  reducers: {
    setBooking(state, action) {
      state.currentBooking = action.payload;
    },
  },
});

export const { setBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
