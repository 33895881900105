import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogPage from './BlogPage';
import BlogPostDetail from './BlogPostDetail';
import { BlogProvider } from './BlogContext';
import { getAllPosts } from '../../services/api'; // Import the API function

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch posts from the API when the component mounts
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await getAllPosts(); // API call to get all posts
        setPosts(data.posts); // Assuming the response contains an array of posts
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const findPostById = (id) => posts.find(post => post.id === parseInt(id));

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <BlogProvider value={{ posts, findPostById }}>
      <Routes>
        <Route path="/" element={<BlogPage />} />
        <Route path="/:title" element={<BlogPostDetail />} />
      </Routes>
    </BlogProvider>
  );
};

export default Blog;
