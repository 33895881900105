import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './RouteDetailPage.css';
import { FaRoad, FaClock } from 'react-icons/fa';
import hatchbackImage from '../../components/assets/hatchback.png';
import sedanImage from '../../components/assets/sedan.png';
import suvImage from '../../components/assets/suv.png';
import PricingPage from '../../components/Customer/PricingPage';
import ReactMarkdown from 'react-markdown';
import { fetchRouteDetailsById, fetchAllTopRoutes } from '../../services/api'; // Adjust the path as necessary


const RouteDetailPage = () => {
  const { routeId } = useParams(); // Extract routeId from the URL
  const [routeDetails, setRouteDetails] = useState(null);
  const [journeyDescription, setJourneyDescription] = useState('');
  const [topRoutesFrom, setTopRoutesFrom] = useState([]);
  const [topRoutesTo, setTopRoutesTo] = useState([]);

  // Dummy data representing a "database" of routes
  const dummyRouteData = {
    'bokaro-to-ranchi-cabs': {
      phoneNumber: "8971717717",
      pickupLocation: "Bokaro Steel City, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      itinerary: [
        "Bokaro Steel City, Jharkhand, India",
        "Ranchi, Jharkhand, India",
      ],
      totalDistance: 120,
      totalTime: "2 hours 30 mins",
      totalCost: {
        hatchback: 1500,
        sedan: 2000,
        suv: 2500,
      },
      images: {
        hatchback: hatchbackImage,
        sedan: sedanImage,
        suv: suvImage,
      },
      status: "Pending",
      _id: "ranchi-to-bokaro-cabs",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      __v: 0,
    },
    'indore-to-bhopal-cabs': {
      phoneNumber: "8971717718",
      pickupLocation: "Indore, Madhya Pradesh, India",
      dropLocation: "Bhopal, Madhya Pradesh, India",
      itinerary: [
        "Indore, Madhya Pradesh, India",
        "Bhopal, Madhya Pradesh, India",
      ],
      totalDistance: 200,
      totalTime: "3 hours 30 mins",
      totalCost: {
        hatchback: 1800,
        sedan: 2300,
        suv: 2800,
      },
      images: {
        hatchback: hatchbackImage,
        sedan: sedanImage,
        suv: suvImage,
      },
      status: "Pending",
      _id: "indore-to-bhopal-cabs",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      __v: 0,
    },

    'dhanbad-to-ranchi-cabs': {
      phoneNumber: "8971717718",
      pickupLocation: "Dhanbad, Madhya Pradesh, India",
      dropLocation: "Ranchi, Madhya Pradesh, India",
      itinerary: [
        "Dhanbad, Madhya Pradesh, India",
        "Ranchi, Madhya Pradesh, India",
      ],
      totalDistance: 200,
      totalTime: "3 hours 30 mins",
      totalCost: {
        hatchback: 1800,
        sedan: 2300,
        suv: 2800,
      },
      images: {
        hatchback: hatchbackImage,
        sedan: sedanImage,
        suv: suvImage,
      },
      status: "Pending",
      _id: "dhanbad-to-ranchi-cabs",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      __v: 0,
    },

  };

  const dummyBookingsData = [
    {
      route: "Ranchi to Jamshedpur",
      pickupLocation: "Ranchi, Jharkhand, India",
      dropLocation: "Jamshedpur, Jharkhand, India",
      distance: "126 kms",
      time: "2 hr 25 mins",
    },
    {
      route: "Ranchi to Bokaro",
      pickupLocation: "Ranchi, Jharkhand, India",
      dropLocation: "Bokaro, Jharkhand, India",
      distance: "126 kms",
      time: "2 hr 25 mins",
    },
    {
      route: "Dhanbad to Ranchi",
      pickupLocation: "Dhanbad, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      distance: "39 kms",
      time: "1 hr 2 mins",
    },
    {
      route: "Dhanbad to Ranchi",
      pickupLocation: "Dhanbad, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      distance: "39 kms",
      time: "1 hr 2 mins",
    },
    {
      route: "Dhanbad to Ranchi",
      pickupLocation: "Dhanbad, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      distance: "39 kms",
      time: "1 hr 2 mins",
    },
    {
      route: "Dhanbad to Ranchi",
      pickupLocation: "Dhanbad, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      distance: "39 kms",
      time: "1 hr 2 mins",
    },
    {
      route: "Kolkata to Bokaro",
      pickupLocation: "Kolkata, West Bengal, India",
      dropLocation: "Bokaro, Jharkhand, India",
      distance: "315 kms",
      time: "6 hr 13 mins",
    },
    {
      route: "Indore to Ujjain",
      pickupLocation: "Indore, Madhya Pradesh, India",
      dropLocation: "Ujjain, Madhya Pradesh, India",
      distance: "55 kms",
      time: "1 hr 15 mins",
    },
    {
      route: "Bhopal to Indore",
      pickupLocation: "Bhopal, Madhya Pradesh, India",
      dropLocation: "Indore, Madhya Pradesh, India",
      distance: "200 kms",
      time: "3 hr 15 mins",
    },
    {
      route: "Indore to Bhopal",
      pickupLocation: "Indore, Madhya Pradesh, India",
      dropLocation: "Bhopal, Madhya Pradesh, India",
      distance: "200 kms",
      time: "3 hr 15 mins",
    },
    {
      route: "Bokaro to Ranchi",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Ranchi, Jharkhand, India",
      distance: "113 kms",
      time: "2 hr 15 mins",
    },
    {
      route: "Bokaro to Jamshedpur",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Jamshedpur, Jharkhand, India",
      distance: "130 kms",
      time: "3 hr 0 mins",
    },
    {
      route: "Bokaro to Dhanbad",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Dhanbad, Jharkhand, India",
      distance: "47 kms",
      time: "1 hr 15 mins",
    },
    {
      route: "Bokaro to Hazaribagh",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Hazaribagh, Jharkhand, India",
      distance: "95 kms",
      time: "2 hr 0 mins",
    },
    {
      route: "Bokaro to Asansol",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Asansol, West Bengal, India",
      distance: "96 kms",
      time: "2 hr 10 mins",
    },
    {
      route: "Bokaro to Kolkata",
      pickupLocation: "Bokaro, Jharkhand, India",
      dropLocation: "Kolkata, West Bengal, India",
      distance: "326 kms",
      time: "7 hr 0 mins",
    },
    {
      route: "Jamshedpur to Bokaro",
      pickupLocation: "Jamshedpur, Jharkhand, India",
      dropLocation: "Bokaro, Jharkhand, India",
      distance: "130 kms",
      time: "3 hr 0 mins",
    },
    {
      route: "Asansol to Bokaro",
      pickupLocation: "Asansol, West Bengal, India",
      dropLocation: "Bokaro, Jharkhand, India",
      distance: "96 kms",
      time: "2 hr 10 mins",
    },
    {
      route: "Hazaribagh to Bokaro",
      pickupLocation: "Hazaribagh, Jharkhand, India",
      dropLocation: "Bokaro, Jharkhand, India",
      distance: "95 kms",
      time: "2 hr 0 mins",
    },
  ];

  const fetchRouteDetails = async () => {
    try {
      // Fetch route details by routeId from the API
      const data = await fetchRouteDetailsById(routeId);
  
      if (data) {
        setRouteDetails(data);
        setJourneyDescription(data.journeyDescription);

  
        // Extract the city name from the full location
        const extractCityName = (location) => location.split(',')[0].trim().toLowerCase();
  
        const normalizeCityName = (cityName) => {
          // Normalize city names for common variations
          if (cityName === 'bokaro steel city') return 'bokaro';
          // Add more normalization rules here if necessary
          return cityName;
        };
  
        const dataPickupCity = normalizeCityName(extractCityName(data.pickupLocation));
        const dataDropCity = normalizeCityName(extractCityName(data.dropLocation));
  
        // Fetch all top routes from the API
        const topRoutesData = await fetchAllTopRoutes();
  
        // Filter top routes based on pickup and drop cities
        const topRoutesFrom = topRoutesData.filter((route) => {
          const routePickupCity = normalizeCityName(extractCityName(route.pickupLocation));
          return routePickupCity === dataPickupCity;
        });
  
        const topRoutesTo = topRoutesData.filter((route) => {
          const routeDropCity = normalizeCityName(extractCityName(route.dropLocation));
          return routeDropCity === dataDropCity;
        });
  
        setTopRoutesFrom(topRoutesFrom);
        setTopRoutesTo(topRoutesTo);
  
        // Debugging: Log the results
        console.log("Top Routes From:", topRoutesFrom);
        console.log("Top Routes To:", topRoutesTo);
      } else {
        console.log(`No data found for the route ID: ${routeId}`);
      }
    } catch (error) {
      console.error("Error fetching route details:", error);
    }
  };
  
  

  // const fetchJourneyDescription = async () => {
  //   try {
  //     const response = await fetch('https://raw.githubusercontent.com/ashwanijha04/IC-Blogs/main/Cabs/Ranchi%20to%20Bokaro/journey_details.md');
  //     const text = await response.text();
  //     setJourneyDescription(text);
  //   } catch (error) {
  //     console.error('Error fetching the journey description:', error);
  //   }
  // };

  useEffect(() => {
    fetchRouteDetails();
  }, [routeId]);

  if (!routeDetails) {
    return <div>Loading...</div>;
  }

  const renderRouteItems = (routes) =>
    routes.map((route, index) => (
      <li key={index} className="route-list-item">
        <div className="route-details">
          <h3 className="route-title">{route.route}</h3>
          <p className="route-meta"><FaRoad /> {route.distance} | <FaClock /> {route.time}</p>
        </div>
        <div className="cab-pricing">
          <button className="book-now-button">Book Now</button>
        </div>
      </li>
    ));

  return (
<div className="route-detail-page">
  <h1>{routeDetails.pickupLocation} to {routeDetails.dropLocation} Cabs, Use "CITYTRIP" Upto Rs 500 Off</h1>

  <div className="route-info">
    <p>
      <strong>Distance:</strong> {routeDetails.totalDistance} kms | <strong>Duration:</strong> {routeDetails.totalTime}
    </p>
  </div>

  <PricingPage bookingDetails={routeDetails} />

  <div className="journey-description-card">
    <h2>Book a Cab on IndoreCabs</h2>
    <ReactMarkdown>{journeyDescription}</ReactMarkdown>
  </div>

  <div className="journey-details-card">
  <h2>Information about the Route</h2>
  <table>
    <tbody>
      <tr>
        <td><strong>Route</strong></td>
        <td>{routeDetails.pickupLocation} to {routeDetails.dropLocation}</td>
      </tr>
      <tr>
        <td><strong>Time Duration</strong></td>
        <td>{routeDetails.totalTime}</td>
      </tr>
      <tr>
        <td><strong>Extra km fare</strong></td>
        <td>{routeDetails.extraKmFare || 'N/A'}</td>
      </tr>
      <tr>
        <td><strong>Distance</strong></td>
        <td>{routeDetails.totalDistance} km</td>
      </tr>
      <tr>
        <td><strong>Car Types</strong></td>
        <td>{routeDetails.carTypes || 'N/A'}</td>
      </tr>
      <tr>
        <td><strong>Fuel Types</strong></td>
        <td>{routeDetails.fuelTypes || 'N/A'}</td>
      </tr>
      <tr>
        <td><strong>Models</strong></td>
        <td>{routeDetails.models || 'N/A'}</td>
      </tr>
    </tbody>
  </table>
</div>


  <div className="top-routes-section-container">
    <div className="top-routes-section">
      <h2>Top Cab Routes from {routeDetails.pickupLocation}</h2>
      <ul className="route-list">
        {renderRouteItems(topRoutesFrom)}
      </ul>
    </div>

    <div className="top-routes-section">
      <h2>Top Cab Routes to {routeDetails.dropLocation}</h2>
      <ul className="route-list">
        {renderRouteItems(topRoutesTo)}
      </ul>
    </div>
  </div>
</div>

  );
};

export default RouteDetailPage;
