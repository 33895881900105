import React, { useState } from 'react';
import { Form, AutoComplete, Input } from 'antd';
import { getSuggestedLocations } from '../../services/api';

const LocationInput = ({ label, formik, options, setOptions, locationOptions, field, itinerary, setItinerary }) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (value) => {
    if (value) {
      try {
        const results = await getSuggestedLocations(value);
        setSuggestions(results.predictions.map(location => ({
          value: location.description
        })));
      } catch (error) {
        console.error('Failed to fetch suggestions', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
    setOptions(
      value
        ? locationOptions.filter((option) =>
            option.value.toLowerCase().includes(value.toLowerCase())
          )
        : []
    );
  };

  const handleSelect = (value) => {
    formik.setFieldValue(field, value);
    setItinerary([...itinerary, value]);
  };

  return (
    <Form.Item
      label={<span className="booking-form-label">{label}</span>}
      validateStatus={formik.errors[field] ? 'error' : ''}
      help={formik.errors[field]}
    >
      <AutoComplete
        options={[...options, ...suggestions]}
        onSelect={handleSelect}
        onSearch={handleSearch}
        value={formik.values[field]}
        onChange={(value) => formik.setFieldValue(field, value)}
      >
        <Input />
      </AutoComplete>
    </Form.Item>
  );
};

export default LocationInput;
