import React, { useState } from 'react';
import Sidebar from './Sidebar';
import PaginationComponent from '../Common/PaginationComponent';
import './BlogPage.css';
import BlogPostItem from './BlogPostItem';
import { useBlog } from './BlogContext';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const { posts } = useBlog(); 
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const currentPosts = posts.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
  const featuredPost = posts[0]; 

  return (
    <div className="blog-page-container">
      <header className="blog-header">
        <h1>Our Blog</h1>
        <p>Stay updated with the latest trends and stories</p>
      </header>

      {featuredPost && (
        <div className="featured-post-container">
          <div
            className="featured-post"
            style={{ backgroundImage: `url(${featuredPost.image})` }}
          >
            <div className="featured-post-content">
              <span className="featured-label">Featured Post</span>
              <h2>{featuredPost.title}</h2>
              <p>{featuredPost.summary}</p>
              <Link to={`/blog/${featuredPost.slug}`} className="read-more-button">
                Read More
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className="blog-page-content">
        <div className="blog-posts-grid">
          {currentPosts.map((post) => (
            <BlogPostItem key={post.slug} post={post} />
          ))}
        </div>
        <Sidebar />
      </div>


      <PaginationComponent
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={posts.length}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default BlogPage;
