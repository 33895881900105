import { List, Typography, Input } from 'antd';
import { Link } from 'react-router-dom';


const Sidebar = () => {
  // Example data; in a real application, fetch this data from your API
  const recentPosts = [
    { id: 2, title: 'How to Choose the Best Cab Service' },
    { id: 3, title: 'CityTaxis Became a Billion-Dollar Company' },
    { id: 3, title: 'CityTaxis Became a Billion-Dollar Company' },
    { id: 3, title: 'CityTaxis Became a Billion-Dollar Company' },
    { id: 3, title: 'CityTaxis Became a Billion-Dollar Company' },
    // Add more recent posts
  ];

  return (
    <div className="sidebar">
      <Input.Search placeholder="Search posts" enterButton />
      <Typography.Title level={4} style={{ color: '#ffffff' }}>Recent Posts</Typography.Title>
      <List
  dataSource={recentPosts}
  renderItem={(item) => (
    <List.Item>
      <Link 
        to={`/blog/${item.id}`} 
        style={{ color: '#ffffff' }}
        onMouseEnter={(e) => e.target.style.color = '#ffaa00'}
        onMouseLeave={(e) => e.target.style.color = '#ffffff'}
      >
        {item.title}
      </Link>
    </List.Item>
  )}
/>

      {/* Add more widgets like Categories, Tags, etc. */}
    </div>
  );
};

export default Sidebar;
