import React from 'react';
import { Form, AutoComplete, Input } from 'antd';

const AddStopInput = ({ addingStop, setAddingStop, newStop, setNewStop, confirmStop, locationOptions }) => {
  return (
    <>
      {addingStop && (
        <Form.Item
          label={<span className="booking-form-label">Add More City</span>}
        >
          <AutoComplete
            options={locationOptions}
            onSelect={confirmStop}
            onSearch={(value) => setNewStop(value)}
            value={newStop}
            onChange={(value) => setNewStop(value)}
            className="booking-form-input"
          >
            <Input />
          </AutoComplete>
        </Form.Item>
      )}
    </>
  );
};

export default AddStopInput;