import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons';
import './BottomNav.css';
import { CustomerServiceOutlined } from '@ant-design/icons';

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <Link to="/" className="nav-item">
        <HomeOutlined className="nav-icon" />
        <p>Home</p>
      </Link>
      {/* <Link to="/book-ride" className="nav-item">
        <CarOutlined className="nav-icon" />
        <p>Book a Ride</p>
      </Link> */}
      <Link to="/rides" className="nav-item">
        <HistoryOutlined className="nav-icon" />
        <p>Rides</p>
      </Link>
      <Link to="/support" className="nav-item">
        <CustomerServiceOutlined className="nav-icon" />
        <p>Support</p>
    </Link>

      <Link to="/profile" className="nav-item">
        <UserOutlined className="nav-icon" />
        <p>Profile</p>
      </Link>
    </div>
  );
};

export default BottomNav;
