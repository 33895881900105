import React from 'react';
import { Typography, Layout } from 'antd';
import './PrivacyContent.css'; // Import the CSS file

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PrivacyContent = () => {
  return (
    <Layout className="privacy-layout">
      <Content className="privacy-content">
        <Title level={1} className="privacy-title">
          Privacy Policy
        </Title>
        <Paragraph className="privacy-paragraph">
          Your privacy is important to us. This privacy policy explains how we handle your personal information and data.
        </Paragraph>
        
        <Title level={2} className="privacy-subtitle">
          1. Information We Collect
        </Title>
        <Paragraph className="privacy-paragraph">
          We collect various types of information in connection with the services we provide, including:
        </Paragraph>
        <Paragraph>
          <ul className="privacy-list">
            <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and other similar contact data.</li>
            <li><strong>Location Data:</strong> We collect your location data to provide location-based services, such as finding the nearest cab.</li>
            <li><strong>Device Information:</strong> We may collect information about your device, such as IP address, browser type, and operating system.</li>
            <li><strong>Usage Data:</strong> We collect data on how you interact with our services, including the pages you visit and the actions you take.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          2. How We Use Your Information
        </Title>
        <Paragraph className="privacy-paragraph">
          We use the information we collect in various ways, including:
        </Paragraph>
        <Paragraph>
          <ul className="privacy-list">
            <li><strong>To Provide and Maintain our Service:</strong> We use your information to deliver the services you request, such as processing your bookings.</li>
            <li><strong>To Improve our Service:</strong> We analyze usage data to understand how our service is used and to make improvements.</li>
            <li><strong>For Marketing and Advertising:</strong> We may use your information to send you promotional messages, marketing offers, and other information that may be of interest to you. We may also use your data to show you targeted advertisements.</li>
            <li><strong>To Communicate with You:</strong> We may contact you with updates, alerts, and other important information regarding your account or our services.</li>
            <li><strong>For Legal and Compliance Purposes:</strong> We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          3. Sharing Your Information
        </Title>
        <Paragraph className="privacy-paragraph">
          We do not share your personal information with third parties except in the following circumstances:
        </Paragraph>
        <Paragraph>
          <ul className="privacy-list">
            <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and marketing services.</li>
            <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          4. Your Data Protection Rights
        </Title>
        <Paragraph className="privacy-paragraph">
          Depending on your location, you may have the following rights regarding your personal information:
        </Paragraph>
        <Paragraph>
          <ul className="privacy-list">
            <li><strong>The right to access:</strong> You have the right to request copies of your personal data.</li>
            <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate.</li>
            <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
            <li><strong>The right to restrict processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li><strong>The right to object to processing:</strong> You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li><strong>The right to data portability:</strong> You have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</li>
          </ul>
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          5. Data Security
        </Title>
        <Paragraph className="privacy-paragraph">
          We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          6. Changes to This Privacy Policy
        </Title>
        <Paragraph className="privacy-paragraph">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </Paragraph>

        <Title level={2} className="privacy-subtitle">
          7. Contact Us
        </Title>
        <Paragraph className="privacy-paragraph">
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@indorecabs.com" className="privacy-link">contact@indorecabs.com</a>.
        </Paragraph>
      </Content>
    </Layout>
  );
};

export default PrivacyContent;
