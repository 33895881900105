import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setBooking } from '../../redux/bookingSlice';
import { registerUser, bookRide, findUserByPhoneNumber } from '../../services/api'; // Import the necessary functions
import { Form, Input, Button, Tabs, Typography, Row, Col, Radio, Checkbox, message, TimePicker, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import LocationInput from './LocationInput';
import ItineraryDisplay from './ItineraryDisplay';
import AddStopInput from './AddStopInput';
import './BookingForm.css';

const { TabPane } = Tabs;
const { Title } = Typography;

const BookingForm = ({ onFinish }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate hook

  const [messageApi, contextHolder] = message.useMessage();
  const [activeTab, setActiveTab] = useState('outstation');
  const [isMultiCity, setisMultiCity] = useState(false);
  const [oneWayItinerary, setOneWayItinerary] = useState([]);
  const [roundTripItinerary, setRoundTripItinerary] = useState([]);
  const [airportItinerary, setAirportItinerary] = useState([]);
  const [localItinerary, setLocalItinerary] = useState([]);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);
  const [addingStop, setAddingStop] = useState(false);
  const [newStop, setNewStop] = useState('');
  const [isRoundTrip, setIsRoundTrip] = useState(false)
  // const history = useHistory(); // Add this hook


  const [journeyDetails, setJourneyDetails] = useState(null);

  const locationOptions = [
    { value: 'Mumbai' },
    { value: 'Delhi' },
    { value: 'Bangalore' },
    { value: 'Hyderabad' },
    // Add more locations as needed
  ];

  const addStop = () => {
    setAddingStop(true);
  };

  const confirmStop = (value) => {
    if (value) {
      if (activeTab === 'outstation' && !isMultiCity) {
        setOneWayItinerary([...oneWayItinerary, value]);
      } else if (activeTab === 'outstation' && isMultiCity) {
        setRoundTripItinerary([...roundTripItinerary, value]);
      } else if (activeTab === 'airport') {
        setAirportItinerary([...airportItinerary, value]);
      } else if (activeTab === 'local') {
        setLocalItinerary([...localItinerary, value]);
      }
      setNewStop('');
    }
    setAddingStop(false);
  };

  const removeStop = (newItinerary) => {
    if (activeTab === 'outstation' && !isMultiCity) {
      setOneWayItinerary(newItinerary);
    } else if (activeTab === 'outstation' && isMultiCity) {
      setRoundTripItinerary(newItinerary);
    } else if (activeTab === 'airport') {
      setAirportItinerary(newItinerary);
    } else if (activeTab === 'local') {
      setLocalItinerary(newItinerary);
    }
  };

  

  const formik = useFormik({
    initialValues: {
      pickupLocation: '',
      dropLocation: '',
      phoneNumber: '',
      // date: null,
      // time: null,
    },
    validationSchema: Yup.object({
      pickupLocation: Yup.string().required('Required'),
      dropLocation: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required').matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),

    }),
    onSubmit: async (values) => {
      try {
        let itinerary = [];
        if (activeTab === 'outstation' && !isMultiCity) {
          itinerary = oneWayItinerary;
        } else if (activeTab === 'outstation' && isMultiCity) {
          itinerary = roundTripItinerary;
        } else if (activeTab === 'airport') {
          itinerary = airportItinerary;
        } else if (activeTab === 'local') {
          itinerary = localItinerary;
        }
    
        const bookingDetails = {
          ...values,
          itinerary,
        };
    
        // Check if user is logged in
        let userId;
        let storedUser;
    
        try {
          storedUser = JSON.parse(localStorage.getItem('user'));
        } catch (error) {
          console.warn("Failed to parse user from localStorage:", error);
          storedUser = null;
        }
    
        if (!storedUser || !storedUser._id) {
          // User is not logged in or no user data in localStorage, check if user exists by phone number
          let existingUser;
          try {
            existingUser = await findUserByPhoneNumber(values.phoneNumber); // Create a function to check user existence
          } catch (error) {
            console.error('Failed to check existing user:', error);
          }
    
          if (existingUser) {
            userId = existingUser._id;
            console.log("User already exists", existingUser)
            localStorage.setItem('user', JSON.stringify(existingUser)); // Save the existing user to localStorage
          } else {
            // If no existing user, register a new one
            const userResponse = await registerUser({ phone: values.phoneNumber });
    
            if (!userResponse || !userResponse.user._id) {
              throw new Error('Failed to register user');
            }
    
            userId = userResponse.user._id;

    
            // Store the user and token in localStorage
            localStorage.setItem('authToken', userResponse.token);
            localStorage.setItem('user', JSON.stringify(userResponse.user));
          }
        } else {
          // User is logged in, get the userId from local storage
          userId = storedUser._id;
        }
    
        // Add userId to booking details
        bookingDetails.userId = userId;
    
        // Proceed to book the ride
        const bookingResponse = await bookRide(bookingDetails);
    
        if (!bookingResponse) {
          throw new Error('Failed to receive a valid booking response');
        }
    
        dispatch(setBooking(bookingResponse));
        setJourneyDetails(bookingResponse);
        messageApi.success('Ride booked successfully!');
        navigate('/booking-details', { state: { bookingDetails: bookingResponse } });
    
      } catch (error) {
        console.error('Failed to book ride', error);
        messageApi.error('Failed to book ride.');
      }
    }, 
  });

  return (
    <div className="booking-form-container">
      {contextHolder}
      <h1 className="booking-form-title">Book a Ride</h1>
      <Tabs activeKey={activeTab} onChange={setActiveTab} className="booking-form-tabs">
        <TabPane tab="Outstation" key="outstation">

          <Form onFinish={formik.handleSubmit} layout="vertical" className="booking-form">
            {oneWayItinerary.length === 0 ||  oneWayItinerary.length === 1 ? (

              <>

              <LocationInput
                label="Pickup Location"
                formik={formik}
                options={pickupOptions}
                setOptions={setPickupOptions}
                locationOptions={locationOptions}
                field="pickupLocation"
                itinerary={oneWayItinerary}
                setItinerary={setOneWayItinerary}
              />

              

              <LocationInput
                    label="Drop Location"
                    formik={formik}
                    options={dropOptions}
                    setOptions={setDropOptions}
                    locationOptions={locationOptions}
                    field="dropLocation"
                    itinerary={oneWayItinerary}
                    setItinerary={setOneWayItinerary}
                  />
              
              </>
            ) : (
              <>
                <ItineraryDisplay
                  itinerary={isMultiCity ? roundTripItinerary : oneWayItinerary}
                  removeStop={removeStop}
                  setPickupLocation={(value) => formik.setFieldValue('pickupLocation', value)}
                  setDropLocation={(value) => formik.setFieldValue('dropLocation', value)}
                />
                  <>


</>
                  


<br>

</br>
<div className='round-trip-checkbox'>
          <Checkbox
          checked={isRoundTrip}
          onChange={(e) => setIsRoundTrip(e.target.checked)}
          className="round-trip-checkbox"
        >
          Round Trip
        </Checkbox>

</div>




              </>
            )}


            <Form.Item
              label={<span className="booking-form-label">Phone Number</span>}
              validateStatus={formik.errors.phoneNumber ? 'error' : ''}
              help={formik.errors.phoneNumber}
            >
              <Input
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                className="booking-form-input"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block className="booking-form-button">
                Search Cabs
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Local / Rental" key="local">
          <Form onFinish={formik.handleSubmit} layout="vertical" className="booking-form">
            {localItinerary.length === 0 ? (
              <LocationInput
                label="Pickup Location"
                formik={formik}
                options={pickupOptions}
                setOptions={setPickupOptions}
                locationOptions={locationOptions}
                field="pickupLocation"
                itinerary={localItinerary}
                setItinerary={setLocalItinerary}
              />
            ) : (
              <ItineraryDisplay
                itinerary={localItinerary}
                removeStop={removeStop}
                setPickupLocation={(value) => formik.setFieldValue('pickupLocation', value)}
                setDropLocation={(value) => formik.setFieldValue('dropLocation', value)}
              />
            )}
            <Form.Item
              label={<span className="booking-form-label">Phone Number</span>}
              validateStatus={formik.errors.phoneNumber ? 'error' : ''}
              help={formik.errors.phoneNumber}
            >
              <Input
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                className="booking-form-input"
              />
            </Form.Item>
            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span className="booking-form-label">Date</span>}
                  validateStatus={formik.errors.date ? 'error' : ''}
                  help={formik.errors.date}
                >
                  <DatePicker
                    id="date"
                    name="date"
                    value={formik.values.date}
                    onChange={(date) => formik.setFieldValue('date', date)}
                    className="booking-form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="booking-form-label">Time</span>}
                  validateStatus={formik.errors.time ? 'error' : ''}
                  help={formik.errors.time}
                >
                  <TimePicker
                    id="time"
                    name="time"
                    value={formik.values.time}
                    onChange={(time) => formik.setFieldValue('time', time)}
                    className="booking-form-input"
                    format="HH:mm"
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item
              label={<span className="booking-form-label">Package</span>}
            >
              <Radio.Group
                value={formik.values.package}
                onChange={(e) => formik.setFieldValue('package', e.target.value)}
                className="trip-type"
              >
                <Radio.Button value="8hrs_80km">8 hrs, 80 km</Radio.Button>
                <Radio.Button value="12hrs_120km">12 hrs, 120 km</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block className="booking-form-button">
                Search Cabs
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Airport" key="Airport">
          <Form onFinish={formik.handleSubmit} layout="vertical" className="booking-form">
            {airportItinerary.length === 0 ? (
              <LocationInput
                label="Pickup Location"
                formik={formik}
                options={pickupOptions}
                setOptions={setPickupOptions}
                locationOptions={locationOptions}
                field="pickupLocation"
                itinerary={airportItinerary}
                setItinerary={setAirportItinerary}
              />
            ) : (
              <>
                <ItineraryDisplay
                  itinerary={airportItinerary}
                  removeStop={removeStop}
                  setPickupLocation={(value) => formik.setFieldValue('pickupLocation', value)}
                  setDropLocation={(value) => formik.setFieldValue('dropLocation', value)}
                />
                {airportItinerary.length === 1 && (
                  <LocationInput
                    label="Drop Location"
                    formik={formik}
                    options={dropOptions}
                    setOptions={setDropOptions}
                    locationOptions={locationOptions}
                    field="dropLocation"
                    itinerary={airportItinerary}
                    setItinerary={setAirportItinerary}
                  />
                )}
              </>
          )}

            <AddStopInput
              addingStop={addingStop}
              setAddingStop={setAddingStop}
              newStop={newStop}
              setNewStop={setNewStop}
              confirmStop={confirmStop}
              locationOptions={locationOptions}
            />
            <Form.Item
              label={<span className="booking-form-label">Phone Number</span>}
              validateStatus={formik.errors.phoneNumber ? 'error' : ''}
              help={formik.errors.phoneNumber}
            >
              <Input
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                className="booking-form-input"
              />
            </Form.Item>
            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span className="booking-form-label">Date</span>}
                  validateStatus={formik.errors.date ? 'error' : ''}
                  help={formik.errors.date}
                >
                  <DatePicker
                    id="date"
                    name="date"
                    value={formik.values.date}
                    onChange={(date) => formik.setFieldValue('date', date)}
                    className="booking-form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="booking-form-label">Time</span>}
                  validateStatus={formik.errors.time ? 'error' : ''}
                  help={formik.errors.time}
                >
                  <TimePicker
                    id="time"
                    name="time"
                    value={formik.values.time}
                    onChange={(time) => formik.setFieldValue('time', time)}
                    className="booking-form-input"
                    format="HH:mm"
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" block className="booking-form-button">
                Search Cabs
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
      

      {journeyDetails && (
        <div className="journey-details">
          <h2>Journey Details</h2>
          <p><strong>Itinerary:</strong> {journeyDetails.itinerary.join(' -> ')}</p>
          <p><strong>Total Distance:</strong> {journeyDetails.totalDistance} km</p>
          <p><strong>Total Time:</strong> {journeyDetails.totalTime}</p>
          <p><strong>Total Cost:</strong> ₹{journeyDetails.totalCost}</p>
        </div>
      )}
    </div>
  );
};

export default BookingForm;
