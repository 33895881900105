import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import bookingReducer from './bookingSlice';
import authReducer from './authSlice';


const store = configureStore({
  reducer: {
    user: userReducer,
    booking: bookingReducer,
    auth: authReducer
  },
});

export default store;
