// About.js
import React from 'react';
import PrivacyContent from '../components/Common/PrivacyContent';

const PrivacyPolicy = () => {

  return (
    <>
      <PrivacyContent />
    </>
  );
};

export default PrivacyPolicy;
